import ChatBot from "../ChatBot";
import ChatBotBehavior from "../ChatBotBehavior";
import EmailValidator from "../Validators/EmailAddress";

export default class {
    ChatBot = null;
    constructor(ChatBotInstance) {
        if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
        this.ChatBot = ChatBotInstance;
    }

    getBehaviorList() {
        let $ChatBot = this.ChatBot;
        return [
            new ChatBotBehavior('captures_name').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('flowStarted')
                .template('Hello! We have a representative available and ready to chat with you. Before we connect you, please share your first and last name.')
                .captureInput(),
            new ChatBotBehavior('captures_email_address').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_name';
                })
                .template('Thanks, {val:captures_name}. What’s your email address?')
                .setValidator(EmailValidator)
                .captureInput(),
            new ChatBotBehavior('captures_allow_contact').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_email_address';
                })
                .template('Thank you for sharing that info.<br/>' +
                    'We use email to learn more about your experience and provide you with the latest information and materials for {binded:brand_name} products. Would you like us to contact you with updates using this email?' +
                    '<br /><button class="input-button" data-input-name="allow_contact" data-input-value="Yes">Yes</button><button class="input-button" data-input-name="allow_contact" data-input-value="No">No</button>'
                )
                .captureInput()
                .disableStandardInput(),
            new ChatBotBehavior('salute').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_allow_contact';
                })
                .template('Understood. Please wait while we connect you with a representative.')
                .captureInput()
                .after(function(){
                    $ChatBot.parentChat.customer_name = $ChatBot.getInput('captures_name');
                    $ChatBot.parentChat.customer_email = $ChatBot.getInput('captures_email_address');
                    $ChatBot.parentChat.allow_email_contact = $ChatBot.getInput('captures_allow_contact');
                    $ChatBot.parentChat.allow_phone_contact = '';
                    $ChatBot.parentChat.initializeOnlineChat();
                })
        ];
    }
}
