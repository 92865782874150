'use strict';

import ChatBotBehavior from "./ChatBotBehavior";
import ChatBotInput from "./ChatBotInput";
import WelcomeBehaviorDecorator from "./BehaviorDecorators/WelcomeBehaviorDecorator";
import CustomerFacingChatRedesign from "../components/CustomerFacingChatRedesign";
import { v1 as uuidv1 } from "uuid";
import WelcomeBehaviorProcessor from "./BehaviorHelpers/WelcomeBehaviorProcessor";

export default function ChatBot(CustomerFacingChatRedesignInstance){
    let _t = this;
    this.inputEnabled = true;
    this.maxInputLength = -1;
    this.parentChat = CustomerFacingChatRedesignInstance;
    var parentChat = this.parentChat;

    let bindings = {};

    let inputs = {};
    let inputTransformers = {};
    /** @type ChatBotInput */
    let inputPointer = null;
    /** @type ChatBotInput */
    let lastUsedInputPointer = null;
    /** @type Array */
    this.behaviors = [];

    this.renderer = function(input){
        // User inputs
        let re = /\{val:([A-z_]*)\}/g;
        input = input.replace(re, (full_match, g1) => {
            let behavior = _t.behaviors.find(e => e.name === g1);
            if (!behavior) return '';
            return inputs[g1].trim();
        });

        // Chat bindings
        re = /\{binded:([A-z_]*)\}/g;
        input = input.replace(re, (full_match, g1) => {
            if (typeof bindings[g1] === "undefined" || !bindings[g1]) return '';
            return bindings[g1].trim();
        });
        return input;
    }

    this.getInput = function(field_name, do_not_transform = false) {
        if (typeof inputs[field_name] === 'undefined') return '';
        let raw = inputs[field_name];
        if (typeof inputTransformers[field_name] === "function" && !do_not_transform) {
            return inputTransformers[field_name](raw);
        } else {
            return raw;
        }
    }

    this.setInput = function(field_name, value) {
        return inputs[field_name] = value;
    }

    this.setInputTransformer = function(field_name, transformerCallback) {
        inputTransformers[field_name] = transformerCallback;
    }

    this.emit = function(event, args) {
        //console.log('Emiting: ' + event);
        for (let i = 0; i < this.behaviors.length; i++) {
            let behavior = this.behaviors[i];
            if (behavior.whenEvents().indexOf(event) > -1) {
                let result = behavior.dispatch();
                if (result) {
                    this.parentChat.addMessageToHistory("bot", result, uuidv1(), false);
                    return this.postSuccessfulEmit(behavior, args);
                } else {
                    if (behavior.stdOutDisabled) {
                        return this.postSuccessfulEmit(behavior, args);
                    }
                }
            }
        }
    }

    this.postSuccessfulEmit = function (behavior, args) {
        if (behavior.capturesInput) {
            inputPointer = new ChatBotInput(behavior)
        }
        this.maxInputLength = behavior.maxInputLength;
        if (behavior.disablesStdIn) {
            this.inputEnabled = false;
        }
        if (typeof behavior.afterCallback === "function") {
            behavior.afterCallback.call(this);
        }
        let possibleUsedPointer = args && args.pointer ? args.pointer : null;
    }

    this.capture = function(input) {
        if (inputPointer) {
            if (typeof inputPointer.ChatBehavior.validator === "function") {
                if (!inputPointer.ChatBehavior.validator(input)) {
                    this.parentChat.addMessageToHistory("bot", inputPointer.ChatBehavior.validatorFailedMessage);
                    return false;
                }
            }
            input = escapeHTML(input.toString().trim());
            inputPointer.input = input;
            inputs[inputPointer.step_name] = input;
            lastUsedInputPointer = inputPointer;
        }
        inputPointer = null;
        this.inputEnabled = true;
        this.emit('message'); //self capture
        if (lastUsedInputPointer
            &&lastUsedInputPointer.ChatBehavior instanceof ChatBotBehavior
            && typeof lastUsedInputPointer.ChatBehavior.afterInputCallback === "function") {
            lastUsedInputPointer.ChatBehavior.afterInputCallback.call(lastUsedInputPointer.ChatBehavior, input);
        }
    }

    this.bindStatic = function(name, value) {
        bindings[name] = value;
        return this;
    }

    this.getBinding = function(name){
        if (typeof bindings[name] === "undefined") throw new Error("No such binded key " + name)
        return bindings[name];
    }

    this.setBehaviors = function(source){
        this.behaviors = [];
        for (let i = 0; i < source.length; i++) {
            this.behaviors[i] = source[i];
        }
    }

    /** Initialization */

    this.defaultBehaviors = [
        new WelcomeBehaviorDecorator('captures_intent', this).make(),
        new WelcomeBehaviorProcessor(this).process()
    ];

    this.setBehaviors(this.defaultBehaviors);
    /** END Initialization */


    /** Helpers **/
    var escapeContext = document.createElement('div');
    escapeContext.style.display = 'none';
    function escapeHTML(html) {
        escapeContext.innerHTML = html;
        return escapeContext.innerText;
    }
    /** END Helpers **/

    this.getLastUsedInputPointer = () => {return lastUsedInputPointer};
}
