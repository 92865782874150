<template>
    <div class="form_section text-left">
        <!-- Step one starts here -->
        <div class="step_form two" v-if="step === 1">
            <div class="step_form_main">
                <div class="step_form_top">
                    <h2>
                        Sales or Service?
                    </h2>
                </div>
                <div class="group_windows_list">
                    <div
                        @click="selectTypes('Sales', 1)"
                        :class="{
                            'group_windows radio checked':
                                fad.searchType == 'Sales',
                            'group_windows radio': fad.searchType != 'Sales'
                        }"
                    >
                        <label><span>Sales</span></label>
                    </div>
                    <div
                        @click="selectTypes('Service', 1)"
                        :class="{
                            'group_windows radio checked':
                                fad.searchType == 'Service',
                            'group_windows radio': fad.searchType != 'Service'
                        }"
                    >
                        <label><span>Service</span></label>
                    </div>
                </div>
            </div>
            <div class="button_cont">
                <button type="button" @click.prevent="next()">Next</button>
            </div>
        </div>
        <!-- ~./Step one -->

        <!-- Step two starts here -->
        <div class="step_form two" v-if="step === 2">
            <div class="step_form_top">
                <h2>When are you planning to buy new windows and/or doors?</h2>
            </div>
            <div class="step_form_main step_two">
                <div class="group_windows_list">
                    <div
                        v-for="(value, key) in this.new_buy_plans"
                        :key="key"
                        :data-key="key"
                        @click="selectTypes(value.id, 2)"
                        :class="{
                            'group_windows radio checked':
                                value.id == quote_request.newBuyPlan,
                            'group_windows radio':
                                value.id != quote_request.newBuyPlan
                        }"
                    >
                        <label
                            ><span>{{ value.name }}</span></label
                        >
                    </div>
                </div>
            </div>
            <div class="button_cont">
                <button
                    type="button"
                    class="custom_btn prev_btn"
                    @click.prevent="prev()"
                >
                    previous
                </button>
                <button
                    type="button"
                    class="custom_btn next_btn"
                    @click.prevent="next()"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- ~./Step two -->

        <!-- Step three starts here -->
        <div class="step_form three" v-if="step === 3">
            <div class="step_form_top">
                <h2>What type of home do you have?</h2>
            </div>
            <div class="step_form_main step_three">
                <div class="group_windows_list">
                    <div
                        v-for="(value, key) in this.home_types"
                        :key="key"
                        :data-key="key"
                        @click="selectTypes(value.id, 4)"
                        :class="{
                            'group_windows radio checked':
                                value.id == quote_request.homeType,
                            'group_windows radio':
                                value.id != quote_request.homeType
                        }"
                    >
                        <label
                            ><span>{{ value.name }}</span></label
                        >
                    </div>
                </div>
            </div>
            <div class="button_cont">
                <button
                    type="button"
                    class="custom_btn prev_btn"
                    @click.prevent="prev()"
                >
                    previous
                </button>
                <button
                    type="button"
                    class="custom_btn next_btn"
                    @click.prevent="next()"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- ~./Step three -->

        <!-- Step four starts here -->
        <div class="step_form three" v-if="step === 4">
            <div class="step_form_top">
                <h2>How many windows do you need installed?</h2>
            </div>
            <div class="step_form_main step_three">
                <div class="group_windows_list">
                    <div
                        v-for="(value, key) in this.window_amount"
                        :key="key"
                        :data-key="key"
                        @click="selectTypes(value.id, 4)"
                        :class="{
                            'group_windows radio checked':
                                value.id == quote_request.windowAmount,
                            'group_windows radio':
                                value.id != quote_request.windowAmount
                        }"
                    >
                        <label
                            ><span>{{ value.name }}</span></label
                        >
                    </div>
                </div>
            </div>
            <div class="button_cont">
                <button
                    type="button"
                    class="custom_btn prev_btn"
                    @click.prevent="prev()"
                >
                    previous
                </button>
                <button
                    type="button"
                    class="custom_btn next_btn"
                    @click.prevent="next()"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- ~./Step three -->

        <!-- Step four starts here -->
        <div class="step_form step_form_field four" v-if="step === 5">
            <div class="step_form_top">
                <h2>How can we contact you?</h2>
            </div>
            <div class="step_form_main">
                <input
                    type="text"
                    value=""
                    v-model="quote_request.firstName"
                    placeholder="First Name"
                />
                <div :class="{ 'form-error': errors.firstName != '' }">
                    {{ this.errors.firstName }}
                </div>

                <input
                    type="text"
                    value=""
                    v-model="quote_request.lastName"
                    placeholder="Last Name"
                />
                <div :class="{ 'form-error': errors.lastName != '' }">
                    {{ this.errors.lastName }}
                </div>
                <input
                    type="email"
                    v-model="quote_request.email"
                    value=""
                    placeholder="Email"
                />
                <div :class="{ 'form-error': errors.email != '' }">
                    {{ this.errors.email }}
                </div>

                <input
                    type="text"
                    v-model="quote_request.phone"
                    value=""
                    placeholder="Phone Number"
                />
                <div :class="{ 'form-error': errors.phone != '' }">
                    {{ this.errors.phone }}
                </div>
                <vue-google-autocomplete
                    ref="address"
                    id="map"
                    classname="xyz"
                    placeholder="Address"
                    v-on:placechanged="getAddressData"
                    country="us"
                    v-model="quote_request.address1"
                >
                </vue-google-autocomplete>
                <div :class="{ 'form-error': errors.address1 != '' }">
                    {{ this.errors.address1 }}
                </div>
                {{ address }}
            </div>
            <div class="button_cont">
                <button
                    type="button"
                    class="custom_btn prev_btn"
                    @click.prevent="prev()"
                >
                    previous
                </button>
                <button
                    type="button"
                    class="custom_btn next_btn"
                    @click.prevent="postLead()"
                >
                    Next
                </button>
            </div>
        </div>
        <!-- ~./Step four -->

        <!-- Step five starts here -->
        <!-- <div class="step_form step_form_field five" v-if="step === 5">
            <div class="step_form_top">
                <h2>Your personal information is safe and secure!</h2>
            </div>
            <div class="step_form_main ">
                <label>Name</label>
                <input
                    type="text"
                    value=""
                    v-model="quote_request.firstName"
                    placeholder="First Name"
                />
                <div :class="{ 'form-error': errors.firstName != '' }">
                    {{ this.errors.firstName }}
                </div>

                <input
                    type="text"
                    value=""
                    v-model="quote_request.lastName"
                    placeholder="Last Name"
                />
                <div :class="{ 'form-error': errors.lastName != '' }">
                    {{ this.errors.lastName }}
                </div>
            </div>
            <div class="button_cont">
                <button
                    type="button"
                    class="custom_btn prev_btn"
                    @click.prevent="prev()"
                >
                    previous
                </button>
                <button
                    type="button"
                    class="custom_btn next_btn"
                    @click.prevent="next()"
                >
                    Next
                </button>
            </div>
        </div> -->
        <!-- ~./Step five -->

        <!-- Step six starts here -->
        <!-- <div class="step_form step_form_field last" v-if="step === 6">
            <div class="step_form_top">
                <h2>
                    Seasonal savings and special promotions are now available!
                </h2>
            </div>
            <div class="step_form_main">
                <label
                    >Let&rsquo;s help you find today&rsquo;s best prices.</label
                >
                <input
                    type="email"
                    v-model="quote_request.email"
                    value=""
                    placeholder="Email"
                />
                <div :class="{ 'form-error': errors.email != '' }">
                    {{ this.errors.email }}
                </div>

                <input
                    type="text"
                    v-model="quote_request.phone"
                    value=""
                    placeholder="Phone Number"
                />
                <div :class="{ 'form-error': errors.phone != '' }">
                    {{ this.errors.phone }}
                </div>
            </div>
            <div class="button_cont">
                <button class="big_button" type="button" @click="postLead()">
                    view my savings
                </button>
            </div>
            <p class="disclaimer">
                By submitting this request, you authorize Modernize, our
                partners, and up to four home service companies that can help
                with your project to call or text you on the phone number
                provided using autodialed and prerecorded calls or messages even
                if your number is on any federal state, or local do not call
                list,. Your consent to this agreement is not required to
                purchase products or services We respect your privacy.
            </p>
        </div> -->
        <!-- ~./Step six -->
    </div>
</template>
<style scoped>
.form_section{
    min-height: 0;
}
</style>

<script>
export default {
    name: "QuoteStepForm",
    props: {},
    data() {
        return this.initData();
    },
    created() {},
    methods: {
        prev() {
            this.step--;
        },
        next() {
            this.step++;
        },
        selectTypes(value, step) {
            if (step == "1") {
                this.fad.searchType = value;
            } else if (step == "2") {
                this.quote_request.newBuyPlan = id;
            } else if (step == "3") {
                this.quote_request.homeType = id;
            } else if (step == "4") {
                this.quote_request.windowAmount = id;
            }
        },
        initData() {
            return {
                step: 1,
                fad: {
                    searchType: "Sales",
                    newBuyPlan: 1,
                    homeType: 1,
                    windowAmount: 1,
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    address1: "",
                    city: "",
                    state: "",
                    zip: ""
                },
                address: ""
            };
        },
        initApiToken() {
            axios
                .post("/api/token/request", {
                    dealer_id: this.dealer.id,
                    secret: this.dealer.api_secret
                })
                .then(
                    response => {
                        if (response.data.success) {
                            console.log(response.data.message);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        }
    }
};
</script>
