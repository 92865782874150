/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue");
import AOS from "aos";
import "aos/dist/aos.css";
import VModal from "vue-js-modal";
var VueScrollTo = require("vue-scrollto");
window.Swal = require("sweetalert2");
import "sweetalert2/src/sweetalert2.scss";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading);

Vue.use(VueScrollTo, {
    offset: -500
});

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
load: {
    key: process.env.MIX_GMAP_API_KEY,
    libraries: "places",
    components: "country:us"
},
installComponents: true
});
window.eventBus = new Vue();

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("lander", require("./components/Lander.vue").default);
Vue.component("finddealer", require("./components/FindDealer.vue").default);
Vue.component("finddealerredesign", require("./components/FindDealerRedesign.vue").default);
Vue.component("finddealereco", require("./components/FindDealerECO.vue").default);
Vue.component(
    "find-dealer-steps",
    require("./components/FindDealerSteps.vue").default
);
Vue.component(
    "finddealer-iframe",
    require("./components/FindDealerIframe.vue").default
);
Vue.component("quotestepform", require("./components/QuoteStepFormRedesign.vue").default);
Vue.component("quotestepformredesign", require("./components/QuoteStepFormRedesign.vue").default);
Vue.component("finddealereze", require("./components/FindDealerEze.vue").default);

Vue.component("getquotebutton", require("./components/GetQuoteButton").default);
Vue.component("customerfacingchatredesign", require("./components/CustomerFacingChatRedesign").default);
Vue.component("pgt-dealer-lander-redesign", require("./components/PgtDealerLanderRedesign.vue").default);
Vue.component("plugin-preloader", require("./components/PluginPreloader.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

if (document.getElementById('app')) {
    const app = pgtVueInstantiate("#app");
} else {
    var elements = document.getElementsByClassName('wpwtb-app');
    for (var i = 0; i < elements.length; i++) {
        pgtVueInstantiate(elements[i]);
    }
}

function pgtVueInstantiate(selector) {
    return new Vue({
        el: selector,
        created() {
            AOS.init();
        }
    });
}

window.pgtVueInstantiate = pgtVueInstantiate;
