<template>
    <!--HEADER-->
    <div class="container md:mx-auto p-4 ez-header-comp">
        <a href="/">
            <img v-if="brand.id === 4" :src="url_prepend + '/assets/images/eze/logo.png'" class="md:m-auto md:w-auto w-32" alt="">
            <img v-if="brand.id === 7" :src="url_prepend + '/images/logos/anlin.png'" class="md:m-auto w-56" alt="">
        </a>
    </div>
    <!--HEADER-->
</template>

<script>
export default {
    props: {
        brand: Object,
        frontend_url: ''
    },
    computed: {
        url_prepend() {
            return this.frontend_url ? this.frontend_url : "";
        },
    }
}
</script>
