import ChatBot from "../ChatBot";
import TextChatOffline from "./TextChatOffline";
import TextChatOnline from "./TextChatOnline";

export default class {
    ChatBot = null;
    constructor(ChatBotInstance) {
        if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
        this.ChatBot = ChatBotInstance;
    }

    getBehaviorList() {
        if (this.ChatBot.parentChat.inOnlineFlow) {
            return new TextChatOnline(this.ChatBot).getBehaviorList();
        } else {
            return new TextChatOffline(this.ChatBot).getBehaviorList();
        }
    }
}
