<script>
import axios from "axios";
import Echo from "laravel-echo";
import { v1 as uuidv1 } from "uuid";
import ChatBot from "../ChatBot/ChatBot";
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
window.io = require("socket.io-client");
window.Pusher = require("pusher-js");

let primary_color;

export default {
  components: {
      DatePicker
  },
  props: {
    dealer: {},
    brand: {},
    fullRemoteAddress: "",
    session: "",
    audioNotificationFile: "",
    primary_color: {
      type: String,
      default: 'pgtGreen'
    },
    logo_url: {
        type: String,
        default: '/assets/images/avatarPlaceholder.png'
    },
    scheduleTypes: {},
    window_amount: {},
    patio_porch: {}, // Eze breeze
    door_amount: {},
    available_appointment_timeframes: [],
    taken_appointment_datetimes: [],
    available_chat_features: []
  },

  data() {
    let defaultInputCallback = function(){ return true; };
    return {
      inputMode: 'text',
      inputCallback: defaultInputCallback,
      defaultInputCallback: defaultInputCallback,
      isOpen: false,
      echoClient: {},
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      confirmed_customer_name: "",
      allow_email_contact: false,
      allow_phone_contact: false,
      message: "",
      messages: [],
      message_counter_until_chat_became_active: 0,
      flag_chat_active: false,
      flag_chat_resuming: false,
      chat_session_id: null,
      isRequestLoading: false,
      chatEndedByDealer: false,
      surveyURL: "",
      audios: {
        notification: {},
      },
      validationError: {
        message: false,
        name: false,
        email: false,
      },
      ChatBot: null, // initialized when mounted
      botName: 'Debbie',
      flow: this.dealer.chat_online_status ? 'Online' : 'Offline',
      datepicker_date: '',
      datepicker_time: '',
      datepicker_customDisabledWorktimeActive: false,
      datepicker_disabledTimes: ()=>{},
      datepicker_takenTimes: ()=>{},
      feedbackLinkClicked: false
    };
  },

  mounted() {
    const vm = this;
    window.eventBus.$on("openChat", function () {
      vm.openChat();
    });

    this.ChatBot = new ChatBot(this).bindStatic('dealer_name', this.dealer.name).bindStatic('brand_name', this.brand.name);
    this.ChatBot.bindStatic('window_amount', this.window_amount);
    this.ChatBot.bindStatic('door_amount', this.door_amount);
    this.ChatBot.bindStatic('patio_porch', this.patio_porch); // EzeBreeze
    this.ChatBot.bindStatic('patio_covered', [
        {id: "1", name: "By the same roof as the house/building"},
        {id: "2", name: "By an extension"},
        {id: "3", name: "Patio is not covered"},
    ]); // EzeBreeze
    this.ChatBot.bindStatic('need_installation', [
        {id: 'yes', name: 'Yes, I need installation services'},
        {id: 'no', name: 'No, I have installer'},
        {id: 'diy', name: 'No, I will install myself'},
        {id: 'helpme', name: 'Not sure, help me decide'},
    ]);
    this.ChatBot.setInputTransformer('captures_need_installation', input => {
        let preset = this.ChatBot.getBinding('need_installation').find(e => e.name == input);
        return preset ? preset.id : preset;
    });
    this.ChatBot.setInputTransformer('captures_patio_porch', input => {
        let preset = this.ChatBot.getBinding('patio_porch').find(e => e.name == input);
        return preset ? preset.id : preset;
    });
    this.ChatBot.setInputTransformer('captures_patio_covered', input => {
        let preset = this.ChatBot.getBinding('patio_covered').find(e => e.name == input);
        return preset ? preset.id : preset;
    });

    this.initializeClient();

    primary_color = this.primary_color;

    let _t = this;
    $('section[data-name="chat"]').on('click', '.input-button', function(e){
        let $button = $(this);
        let inputName  = $button.data('input-name');
        let inputValue = $button.data('input-value');
        if (inputName && inputValue) {
            _t.message = inputValue;
            _t.send();
            $('button.input-button[data-input-name="' + inputName + '"]', 'section[data-name="chat"]').remove();
        }
    });

    window.toggleChat = this.toggleChat;
  },

  methods: {
    toggleChat() {
      document.querySelector("body").classList.remove('lock');
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.querySelector("body").classList.add('lock');
        this.focusMessageInput();
        this.ChatBot.emit('chatOpened');
      }
    },
    openChat() {
      this.isOpen = true;
      document.querySelector("body").classList.add('lock');
      this.focusMessageInput();
      this.ChatBot.emit('chatOpened');
    },
    focusMessageInput() {
      this.$nextTick(() => {
        this.$refs.messageInput.focus();
      });
    },
    initializeClient() {
      let remoteAddress = this.fullRemoteAddress;
      let remoteAddressMeta = document.querySelector(
        'meta[name="chat-server"]'
      ).content;
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER
        //transports: ["websocket", "polling"],
        //host: remoteAddress,
      });
      this.loadAudio();
      this.registerErrorHandling();
      this.loadActiveChatIfFound();
    },

    registerErrorHandling() {
      let ws = null;
      window.Echo && window.Echo.connector && window.Echo.connector.socket && (ws = window.Echo.connector.socket.io.engine.transport.ws);
      if (ws) {
          ws.onerror = function(error){
              // Fires only once, because echoClient's connector will change after error
              if (error.currentTarget.readyState !== 1) {
                  axios.post('/landing/chat/log-error', {
                      source: 'socket.customer',
                      data:    null
                  });
              }
          };
      }
    },
    loadActiveChatIfFound() {
      let _t = this;
      axios
        .post("/landing/chat/is-active", {
          dealer: _t.dealer.id,
        })
        .then((r) => {
          if (r.data && r.data.active) {
            //this.reInitializeSession(r.data.active);
          }
        })
        .catch()
        .finally();
    },
    send() {
      if (!this.validate()) return;
      let customValidationPasses = this.inputCallback();
      if (!customValidationPasses) return;
      let message = this.message;
      this.addCustomerMessage();
      this.inputCallback = this.defaultInputCallback;
      this.ChatBot.capture(message);
    },
    initializeOnlineChat() {
      let _t = this;
      this.isRequestLoading = true;

      let message = this.customer_name + ' has started a chat and is interested in ' + this.brand.name + ' products. Say hello to get started!';

      axios
        .post("/landing/chat/start", {
          customer_name: _t.customer_name,
          customer_email: _t.customer_email,
          customer_phone: _t.customer_phone,
          dealer: _t.dealer.id,
          brand: _t.brand.id,
          message: message,
          allow_email_contact: _t.allow_email_contact.toLowerCase() === 'yes' ? 1 : 0,
          allow_phone_contact: _t.allow_phone_contact.toLowerCase() === 'yes' ? 1 : 0
        })
        .then((r) => {
          this.flag_chat_active = true;
          this.confirmed_customer_name = this.customer_name;
          let chat_session = r.data.body.chat_session;
          this.chat_ession_id = chat_session;
          let echoClient = window.Echo;
          _t.subscribe(echoClient, chat_session);
          _t.message_counter_until_chat_became_active = _t.messages.length;
          _t.ChatBot.capture(message);
        })
        .catch((e) => {})
        .finally(() => {
          _t.isRequestLoading = false;
        });
    },
    storeOfflineChatAndEndSession() {
      let _t = this;
      this.isRequestLoading = true;

      let message = _t.ChatBot.getInput('captures_reason');

      axios
        .post("/landing/chat/start-offline", {
          customer_name: _t.customer_name,
          customer_email: _t.customer_email,
          customer_phone: _t.customer_phone,
          dealer: _t.dealer.id,
          brand: _t.brand.id,
          message: message,
          allow_email_contact: _t.allow_email_contact.toLowerCase() === 'yes' ? 1 : 0,
          allow_phone_contact: _t.allow_phone_contact.toLowerCase() === 'yes' ? 1 : 0
        })
        .then((r) => {
          this.flag_chat_active = true;
          this.chatEndedByDealer = true;
          this.confirmed_customer_name = this.customer_name;
          let chat_session = r.data.body.chat_session;
          this.chat_ession_id = chat_session;
          let echoClient = window.Echo;
          _t.message_counter_until_chat_became_active = _t.messages.length;
        })
        .catch((e) => {})
        .finally(() => {
          _t.isRequestLoading = false;
        });
    },
    reInitializeSession(session_id) {
      this.flag_chat_active = true;
      this.flag_chat_resuming = true;
      this.chat_ession_id = session_id;
      this.subscribe(window.Echo, session_id);
      window.chatVisible();
    },
    subscribe(echoClient, session) {
      let _t = this;
      echoClient
        .channel(`chat.session.${session}.customersub`)
        .listen(".App\\Events\\ChatDealerMessage", (e) => {
          _t.addMessageToHistory("dealer", e.message, e.message_uuid, false, e.links_to);
          _t.playAudioIfAble(_t.audios.notification);
          _t.markReceived(e.message_uuid);
        })
        .listen(".App\\Events\\ChatMarkMessageReceivedByDealer", (e) => {
          let message = _t.getMessageFromUuid(e.message_uuid);
          message.markRead();
        })
        .listen(".App\\Events\\ChatEndedByDealer", (e) => {
          this.chatEndedByDealer = true;
          this.surveyURL = e.survey_url;
          this.scrollToBottom();
        });
    },
    addCustomerMessage() {
      let new_message_uuid = uuidv1();
      this.addMessageToHistory("customer", this.message, new_message_uuid);
      let tmpMessage = this.message;
      this.message = "";
      let _t = this;
      if (this.inOnlineFlow && this.flag_chat_active) {
          axios
              .post("/landing/chat/post", {
                  message: tmpMessage,
                  dealer: _t.dealer.id,
                  message_id: new_message_uuid,
              })
              .then((r) => {})
              .catch((e) => {})
              .finally(() => {});
      }
    },
    addMessageToHistory(from, text, message_id, markRead, links_to) {
      message_id = typeof message_id !== "undefined" ? message_id : null;
      markRead = typeof markRead !== "undefined" ? markRead : false;
      if (from !== "customer" && from !== "dealer" && from !== "bot") return false;
      let new_message = new ChatMessage(from, text, message_id, null, this.messages.length + 1);
      new_message.links_to = links_to ? links_to : null;
      if (markRead) {
        new_message.markRead();
      }
      this.messages.push(new_message);
      this.scrollToBottom();
      return new_message;
    },
    scrollToBottom() {
      setTimeout(() => {
        let $chatMessagesContainer = $("#chat-messages-container");
        $chatMessagesContainer.stop();
        $chatMessagesContainer.animate({
          scrollTop: $chatMessagesContainer[0].scrollHeight,
        });
      }, 25);
    },
    validate() {
      if (this.inputMode === 'text') {
          return this.validateTextInput();
      } else if (this.inputMode === 'datetime') {
          return true;
      } else {
          return false;
      }
    },
    validateTextInput() {
        if (this.flag_chat_active) {
            this.clearValidationErrors();
            if (this.message.toString().trim() === "") {
                this.validationError.message = true;
                return false;
            } else {
                return true;
            }
        } else {
            this.clearValidationErrors();
            let errFound = false;
            if (this.message.toString().trim() === "") {
                errFound = true;
                this.validationError.message = true;
            }
            // if (this.customer_name.trim() === "") {
            //   errFound = true;
            //   this.validationError.name = true;
            // }
            // if (this.customer_email.trim() === "") {
            //   errFound = true;
            //   this.validationError.email = true;
            // }
            if (errFound) {
                return false;
            } else {
                return true;
            }
        }
    },
    clearValidationErrors() {
      this.validationError.message = false;
      // this.validationError.name = false;
      // this.validationError.email = false;
    },
    loadAudio() {
      if (this.audioNotificationFile) {
        this.audios.notification = new Audio(this.audioNotificationFile);
      }
    },
    playAudioIfAble(audio) {
      if (typeof audio !== "undefined" && audio instanceof Audio) {
        audio.play();
      }
    },
    markReceived(message_uuid) {
      axios
        .post("/landing/chat/mark-received", {
          dealer: this.dealer.id,
          message_uuid: message_uuid,
        })
        .catch((e) => console.error(e))
        .finally();
    },
    messageBoxKeyUp(event) {
      if (typeof event.keyCode !== "undefined" && event.keyCode === 13) {
        this.send();
        event.preventDefault();
      }
    },
    chatCollapse() {
      window.chatVisible(false);
    },
    getMessageFromUuid(message_uuid) {
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].message_id == message_uuid) {
          return this.messages[i];
        }
      }
      return null;
    },
    setDateTimeInput(cb) {
        this.inputMode = 'datetime';
        let $datepicker;
        let $timepicker;
        setTimeout(() => {
            // Enable only days which have working hours data
            let disabledDays = [0, 6]; // Disable sundays and saturdays by default
            if (this.areWorkingHoursRestricted) {
                disabledDays = [];
                for (let dayIndex = 0; dayIndex <= 6; dayIndex++) {
                    if (!this.available_appointment_timeframes[dayIndex] || !this.available_appointment_timeframes[dayIndex].length) {
                        disabledDays.push(dayIndex);
                    }
                }
            }
            // TODO: repurpose and delete
            let timepickerOptions = {
                timeFormat: 'h:ia',
                interval: 30,
                minTime: '8:00am',
                maxTime: '5:00pm',
                defaultTime: '11',
                startTime: '09:00',
                dynamic: false,
                dropdown: true,
                scrollbar: true,
                template: 'modal',
                closeOnWindowScroll: true
            };
            // TODO: Refactor
            let refactorOnDatepickerChangeDate = () => {
                let parts = $datepicker.val().split('/');
                let yyyymmdd = parts[2] + '-' + parts[0] + '-' + parts[1];
                let extraOptions = {};
                let realDate = new Date(yyyymmdd);


                if (this.areWorkingHoursRestricted) {
                    // Enable only the working hours for that day
                    let dayOfWeek = realDate.getUTCDay();
                    extraOptions.minTime = this.available_appointment_timeframes[dayOfWeek][0];
                    extraOptions.maxTime = this.available_appointment_timeframes[dayOfWeek][1];

                    this.datepicker_disabledTimes = {

                    };

                    // Disable certain datetimes if an appointment is already set for said datetime
                    if (typeof this.taken_appointment_datetimes[yyyymmdd] !== "undefined") {
                        extraOptions.disableTimeRanges = this.taken_appointment_datetimes[yyyymmdd];
                    }
                }

                let fullOptions = {};
                Object.assign(fullOptions, timepickerOptions)
                Object.assign(fullOptions, extraOptions);
                $timepicker.timepicker(fullOptions);
                $timepicker.timepicker('setTime', '');
                $timepicker.on('changeTime', () => {
                    this.message = [$datepicker.val(), $timepicker.val()].join(' @ ');
                });
                this.message = [$datepicker.val(), $timepicker.val()].join(' @ ');
            };
        }, 150);
        this.inputCallback = function(){
            let date = this.datepicker_date;
            let time = this.datepicker_time;
            if (!date || !time) return;
            let dateFormatted = this.leftpad((date.getMonth() + 1), 2, '0') + '/'
                + this.leftpad(date.getDate(), 2, '0')
                + '/' + date.getFullYear();
            let timeFormatted = time.getHours() % 12 + ':'
                + this.leftpad(time.getMinutes(), 2, '0')
                + '' + (time.getHours() - 12 <= 0 ? 'am' : 'pm');
            this.datepicker_date = '';
            this.datepicker_time = '';
            this.inputMode = 'text';
            this.message = dateFormatted + ' @ ' + timeFormatted;
            cb(dateFormatted, timeFormatted);
            return true;
        }
    },
    getDealer() {
        return this.dealer;
    },
    getBrand() {
        return this.brand;
    },
    leftpad(input, minLength, filler) {
        input = input.toString();
        let diff = minLength - input.length;
        if (diff > 0) {
            return filler.repeat(diff).toString().concat(input);
        }
        return input;
    },
      datetimePickerDisabledDays(date) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          return date < new Date(today.getTime() + (3 * 24 * 3600 * 1000)) // not before three days from now
            || (
                this.areWorkingHoursRestricted ? (
                    this.available_appointment_timeframes[date.getDay()] && !this.available_appointment_timeframes[date.getDay()].length
                ) : (
                    date.getDay() === 0 || date.getDay() === 6
                )
              ) // if working hours are restricted, there has to be a working hour timeframe for that day. This is mostly for weekends
      },
      datetimePickerDisabledTime(time) {
        if (this.datepicker_customDisabledWorktimeActive) {
            return this.datepicker_disabledTimes(time) || this.datepicker_takenTimes(time);
        } else {
            return (time.getHours() < 8 || time.getHours() > 17);
        }
      },
      loadAvailableWorkHours() {
          this.datepicker_time = '';
          this.datepicker_disabledTimes = ()=>{};
          this.datepicker_takenTimes = ()=>{};
          if (this.areWorkingHoursRestricted) {
              // Enable only the working hours for that day
              let dayOfWeek = this.datepicker_date.getUTCDay();
              let minTime = this.humanTimeToMilitary(this.available_appointment_timeframes[dayOfWeek][0]);
              let maxTime = this.humanTimeToMilitary(this.available_appointment_timeframes[dayOfWeek][1]);
              let yyyymmdd = this.datepicker_date.getUTCFullYear().toString()
                  + '-' + this.leftpad(this.datepicker_date.getUTCMonth() + 1, 2, '0')
                  + '-' + this.leftpad(this.datepicker_date.getUTCDate(), 2, '0');

              if (typeof minTime.hour === 'number' && typeof maxTime.hour === 'number') {
                  this.datepicker_disabledTimes = time => {
                      return time.getHours() < minTime.hour
                          || (time.getHours() === minTime.hour && time.getMinutes() < minTime.minutes)
                          || time.getHours() > maxTime.hour
                          || (time.getHours() === maxTime.hour && time.getMinutes() > maxTime.minutes);
                  };
                  this.datepicker_customDisabledWorktimeActive = true;
              }

              // Disable certain datetimes if an appointment is already set for said datetime
              if (typeof this.taken_appointment_datetimes[yyyymmdd] !== "undefined") {
                  console.log('entered');
                  let boundariesCheck = [];
                  this.datepicker_takenTimes = (time) => {
                      this.taken_appointment_datetimes[yyyymmdd].map(boundaryEntry => {
                          let minTime = this.humanTimeToMilitary(boundaryEntry[0]);
                          let maxTime = this.humanTimeToMilitary(boundaryEntry[1]);
                          boundariesCheck.push(function(time){
                              return (time.getHours() === minTime.hour && time.getMinutes() >= minTime.minutes && !(time.getHours() === maxTime.hour && time.getMinutes() >= maxTime.minutes))
                              || (time.getHours() > minTime.hour && time.getHours() < maxTime.hour)
                              || (time.getHours() === maxTime.hour && time.getMinutes() < maxTime.minutes);
                          });
                      });
                      for (let i = 0; i < boundariesCheck.length; i++) {
                          if (boundariesCheck[i](time)) {
                              return true;
                          }
                      }
                  };
              }
          } else {
              this.datepicker_customDisabledWorktimeActive = false;
          }
      },
      humanTimeToMilitary(input) {
        if (!input) return;
        let parts = input.match(/([0-9]{1,2}):([0-9]{1,2}) *(am|pm)/i);
        if (!parts || parts.length < 4) return;
        let hours = parseInt(parts[1]);
        let minutes = parseInt(parts[2]);
        let modifier = parts[3].toLowerCase();
        if (modifier === 'am') {
            return {hour: hours, minutes: minutes};
        }
        if (modifier === 'pm') {
            return {hour: hours === 12 ? hours : hours + 12, minutes: minutes};
        }
      }
  },

  computed: {
    placeholder() {
      if (this.messages.length) {
        return "";
      }
      if (this.flag_chat_resuming) {
        return (
          "Resume your chat with " + this.dealerName + " by sending a message"
        );
      }
      return "Send a message to start chatting with " + this.dealerName;
    },

    dealerName() {
      return this.dealer.name;
    },

    footerColor() {
      switch (this.brand.id) {
        case "1": // PGT
            return '#EDF2F7';
            break;
          case "2": // CGI
            return '#F1F1F0';
            break;
          case "3": // WD
            return '#D7D2CB';
            break;
          case "4": // EZB
            return '#F3F1EF';
            break;
          case "6": // ECO
            return '#EBEBEB';
            break;

          default:
            return '#EDF2F7';
      }
    },
    buttonColor() {
      switch (this.brand.id) {
        case 1: // PGT
          return '#254C5A';
          break;
        case 2: // CGI
          return '#b52c36';
          break;
        case 3: // WD
          return '#B9975B';
          break;
        case 4: // EZB
          return '#326295';
          break;
        case 6: // ECO
          return '#6DA947';
          break;

        default:
          return '#EDF2F7';
      }
    },
    inOnlineFlow() {
        return this.flow === 'Online';
    },
    inOfflineFlow() {
        return !this.inOnlineFlow;
    },
    inputDisabledByBot() {
        return this.ChatBot !== null && !this.ChatBot.inputEnabled;
    },
    areWorkingHoursRestricted() {
        return this.available_appointment_timeframes.filter(i => i && i.length > 1).length > 0;
    },
    brandFloatingChatButtonColorClass() {
        if (this.brand.id == 7) {
            return 'bg-anlinGold';
        }
        return `bg-${primary_color}`;
    }
  },
};

class ChatMessage {
  constructor(from, text, message_id, datetime, index, links_to) {
    this.from = from;
    this.text = text;
    this.message_id = message_id;
    this.datetime = typeof datetime !== "undefined" && datetime ? datetime : new Date();
    this.status = 0;
    this.index = index;
    this.links_to = links_to ? links_to : null;
  }

  markRead() {
    this.status = 2;
  }

  click() {
      if (!this.links_to) return;
      window.open(this.links_to, '_blank');
  }

  getStatusClassName() {
    if (this.from === "dealer") {
      return "from-dealer";
    } else if (this.from === "customer") {
      if (this.status === 0) {
        return "from-customer not-received";
      } else if (this.status === 1) {
        return "from-customer not-received";
      } else if (this.status === 2) {
        return "from-customer received";
      }
    } else if (this.from === "bot") {
        return "from-bot";
    }
  }

  getStatusOnlyClassName(flow, flag_chat_active, active_threshold) {
    if (this.from === "customer") {
      if (flow === 'Offline' || !flag_chat_active || this.index <= active_threshold) {
          return "bg-" + primary_color;
      }
      if (this.status === 0) {
        return "";
      } else if (this.status === 1) {
        return "";
      } else if (this.status === 2) {
        return "bg-" + primary_color;
      }
    }
    return "";
  }
}
</script>

<template>
  <section data-name="chat" class="fixed right-0 md:right-10 bottom-12 z-50">
    <button
      @click="toggleChat"
      id="chat-button"
      type="button"
      :class="`
        h-12
        w-12
        md:h-20
        md:w-20
        ${brandFloatingChatButtonColorClass}
        absolute
        right-10
        -top-10
        flex
        justify-center
        items-center
        rounded-full
      `"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 md:h-12 md:w-12 text-white z-0 pointer-events-none"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- chat -->
    <div
      v-show="isOpen"
      class="
        md:shadow-2xl
        fixed
        md:absolute
        md:bottom-20
        lg:right-10
        right-0
        z-50
        chat-popup
      "
    >
      <div class="h-full w-full bg-white flex flex-col justify-between shadow-2xl md:shadow-none">
        <!-- chat header -->
        <div :class="`w-full bg-${primary_color} pb-20 pt-12 md:py-14 relative px-10`">
          <button
            class="absolute top-6 right-6 text-white"
            @click="toggleChat"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="absolute md:top-16 top-20">
            <div class="flex justify-start space-x-4">
              <div
                class="
                  h-24
                  w-24
                  md:w-32
                  md:h-32
                  rounded-full
                  px-4
                  md:px-0
                  bg-white
                "
              >
                  <div
                      class="chat-avatar"
                      :style="'background: url(\'' + logo_url + '\')'"
                  ></div>
              </div>
              <div class="text-white -mt-1">
                <h1
                  class="text-sm md:text-xl font-semibold"
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 400px;
                  "
                >
                  {{ dealer.name }}
                </h1>
                <div class="flex justify-start items-center space-x-2 mt-1 new-chat-message">
                  <span class="h-2 w-2 rounded-full" :class="this.dealer.chat_online_status ? 'bg-green' : 'bg-gray-500'"></span>
                  <span class="text-sm font-light">{{ this.dealer.chat_online_status ? 'Online' : 'Offline' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- chat body -->
        <div class="ml-10 pr-16 overflow-y-auto mt-20 pb-10 chat-body" id="chat-messages-container">
          <div class="mt-4" v-for="thisMessage in messages" :class="thisMessage.getStatusClassName()">
            <div class="w-full flex flex-1 justify-start items-start" v-if="thisMessage.from == 'dealer' || thisMessage.from == 'bot'">
              <div
                v-if="thisMessage.from == 'dealer'"
                class="h-10 w-10 rounded-full mr-3"
              >
                  <div
                      class="chat-avatar"
                      :style="'background: url(\'' + logo_url + '\')'"
                  ></div>
              </div>
              <div
                  v-if="thisMessage.from == 'bot'"
                  class="px-4 h-10 w-10 rounded-full mr-3 avatar-bg"
                  style="background: url('/assets/images/Debbie.svg')"
              ></div>
              <div class="w-full">
                <p class="mt-1">{{ thisMessage.from == 'dealer' ? dealerName : botName }}</p>
                <div :class="{
                    'py-3 px-3 text-sm rounded-lg bg-gray-200 mt-2 new-chat-message': !thisMessage.links_to,
                    'py-3 px-3 text-sm rounded-lg bg-gray-200 mt-2 clickable new-chat-message': thisMessage.links_to,
                }" v-html="thisMessage.text" @click="thisMessage.click()">

                </div>
              </div>
            </div>

            <div class="w-full flex justify-start items-start mt-4" v-else>
              <div class="w-full">
                <p class="text-right">{{ customer_name ? customer_name : 'Homeowner'}}</p>
                <div
                  :class="thisMessage.getStatusOnlyClassName(flow, flag_chat_active, message_counter_until_chat_became_active)"
                  class="
                    py-3
                    px-3
                    text-sm
                    rounded-lg
                    bg-gray-400
                    text-white
                    mt-2
                  "
                >
                  <p>{{ thisMessage.text }}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="chatEndedByDealer">
                <p class="chat-ended-notice">
                    This chat session has ended
                </p>
                <div class="py-4" v-show="surveyURL && !feedbackLinkClicked">
                    <p class="text-center">
                        Would you like to leave us your feedback?
                    </p>
                    <div class="text-center">
                        <a target="_blank" @click="feedbackLinkClicked = true" :href="surveyURL">
                            <button class="btn btn-primary px-4">Yes</button>
                        </a>
                        <button class="btn btn-light px-4" @click="toggleChat">No</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- chat message box -->
        <div
          :style="`background-color: ${footerColor};`"
          class="
            w-full
            h-28
            flex
            justify-between
            items-start
            pl-10
            pr-4
            md:pr-16
            relative
          "
          style="min-height: 7rem"
          ref="input_container"
        >
          <textarea
            v-if="inputMode === 'text'"
            v-model="message"
            :placeholder="placeholder" :disabled="isRequestLoading || chatEndedByDealer || inputDisabledByBot" @keyup="messageBoxKeyUp"
            ref="messageInput"
            :style="(inputDisabledByBot ? `background-color: ${footerColor};` : `background-color: #FFFFFF;`) + 'resize: none;'"
            class="w-4/5 text-base outline-none mt-4"
            :class="{ 'input-invalid': validationError.message }"
            :maxlength="ChatBot ? ChatBot.maxInputLength : -1"
            rows="3"
          ></textarea>
          <div
            v-if="inputMode === 'datetime'"
            class="sm:block md:flex mt-4"
          >
              <div class="sm:hidden"> <!-- Mobile -->
                <div class="grid grid-datetimepickers gap-2">
                    <label class="form-control-label" style="line-height: 40px">
                        <i class="fa fa-calendar"> </i>
                    </label>
                    <date-picker v-model="datepicker_date" format="MM/DD/Y" @change="loadAvailableWorkHours" :disabled-date="datetimePickerDisabledDays"></date-picker>

                    <label class="form-control-label" style="line-height: 40px">
                        <i class="fa fa-clock"> </i>
                    </label>
                    <date-picker v-model="datepicker_time" type="time" format="h:mm a" :minute-step="30" :disabled-time="datetimePickerDisabledTime"></date-picker>
                </div>
              </div>
              <div class="hidden sm:block"> <!-- Desktop -->
                  <div class="form-group inline-block">
                      <label class="form-control-label">
                          <i class="fa fa-calendar"> </i>
                      </label>
                      <date-picker v-model="datepicker_date" format="MM/DD/Y" @change="loadAvailableWorkHours" :disabled-date="datetimePickerDisabledDays"></date-picker>
                  </div>
                  <div class="form-group md:ml-4 inline-block">
                      <label class="form-control-label">
                          <i class="fa fa-clock"> </i>
                      </label>
                      <date-picker v-model="datepicker_time" type="time" format="h:mm a" :minute-step="30" :disabled-time="datetimePickerDisabledTime"></date-picker>
                  </div>
              </div>
          </div>
          <button class="mr-4" @click="send" :disabled="isRequestLoading || chatEndedByDealer">
            <svg
              :class="`text-${primary_color} h-8 mt-4`"
              viewBox="0 0 513 513"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.1769 464.429L496.177 256.429L16.1769 48.4288V208.429L336.177 256.429L16.1769 304.429V464.429Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.chat-popup {
  width: 680px;
  height: 600px;
}

.chat-avatar {
    height: 100%;
    width: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    transform: scale(0.82);
    background-position-y: 30% !important;
}

.chat-body {
  max-height: 360px;
}

.new-chat-message {
    overflow-wrap: break-word;
    a, a:visited, a:hover, a:active {
        text-decoration: underline;
        color: #0c5460;
    }
}

@media only screen and (max-width: 767px) {
  .chat-body {
    max-height: 100%;
  }
  .chat-popup {
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    max-height: -webkit-fill-available;
    bottom: 0;
    top: 0;
  }
}
.avatar-bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.input-button {
    border-radius: 15px;
    background-color: rgb(198, 211, 224);
    color: #000000;
    min-width: 100px;
    margin-top: 7px;
    padding: 2px 5px 2px 5px;
}

.input-button:not(:last-of-type) {
    margin-right: 15px;
}

.clickable {
    color: #539fbd;
    text-decoration: underline;
    cursor: pointer;
}

.grid-datetimepickers {
    grid-template-columns: 1fr 8fr;
}

@media only screen and (max-width: 767px) {
  .lock {
    overflow: hidden;
  }
}

.mx-time-column .mx-time-item.disabled {
    display: none;
}

</style>
