export default {
    computed: {
        showLegal() {
            const brand_int = parseInt(this.brand);
            return [1,2,3,4,6].indexOf(brand_int) > -1;
        },
        brandPrivacyPolicy() {
            switch (parseInt(this.brand)) {
                case 1:
                    return 'https://www.pgtwindows.com/privacy-policy/';
                case 2:
                    return 'https://cgiwindows.com/privacy-policy/';
                case 3:
                    return 'https://windoorinc.com/privacy-policy/';
                case 4:
                    return 'https://ezebreezehome.com/privacy-policy/';
                case 6:
                    return 'https://ecowindowsystems.com/privacy-policy-2/';
            }
            return '#';
        },
        brandContactPhone() {
            switch (parseInt(this.brand)) {
                case 1:
                    return '866-801-0638';
                case 2:
                    return '866-972-0597';
                case 3:
                    return '866-363-2387';
                case 4:
                    return '866-587-3469';
                case 6:
                    return '866-972-0597';
            }
            return '';
        }
    }
}
