'use strict';
export default function(input){
    if (!input || !input.length) return input;
    let result = [];
    for (let i = 0; i < input.length; i++) {
        let thisOption = input[i];
        let preset = this.ChatBot.getBinding('patio_porch').find(e => e.name === thisOption);
        result.push(preset ? preset.id : thisOption);
    }
    return result;
}
