<template>
    <div class="get-quote relative z-10">
        <div
            class="content-area text-white relative z-20 md:float-right md:w-3/5 py-20 px-10 lg:p-20"
        >
            <h2 class="text-center max-w-md mx-auto">
                {{dealer.name}}
            </h2>

            <form v-on:submit.prevent="console.log(form)">
                <div class="font-hairline">
                    <div class="flex flex-row mb-6">
                        <input
                            v-model="firstName"
                            type="text"
                            placeholder="Firstname"
                            class="bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex flex-row mb-6">
                        <input
                            v-model="lastName"
                            type="text"
                            placeholder="Lastname"
                            class="bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex flex-row mb-6">
                        <input
                            v-model="email"
                            type="email"
                            placeholder="Email"
                            class="bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex flex-row mb-6">
                        <input
                            v-model="phone"
                            type="text"
                            placeholder="Telephone Number"
                            class="bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex flex-column mb-6">
                        <input
                            v-model="street"
                            type="text"
                            placeholder="Street"
                            class="mr-3 bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <input
                            v-model="city"
                            type="text"
                            placeholder="City"
                            class="ml-3 bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex flex-column mb-6">
                        <select
                            v-model="state"
                            class="mr-3 bg-white-alpha-3 block border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        >
                            <option value="" selected="selected">State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <input
                            v-model="zip"
                            type="text"
                            placeholder="Zip"
                            class="ml-3 bg-white-alpha-3 block appearance-none placeholder-white border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>

                    <div class="flex-row mb-6">
                        <label class="block text-xs text-center mb-4"
                            >When are you planning to buy new windows and/or
                            doors?*</label
                        >
                        <select
                            v-model="dateToBuy"
                            class="mr-3 bg-white-alpha-3 block border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                            required
                        >
                            <option value="" selected="selected"
                                >Select One</option
                            >
                            <option value="option1">Options 1</option>
                            <option value="option2">Options 2</option>
                            <option value="option3">Options 3</option>
                            <option value="option4">Options 4</option>
                        </select>
                    </div>

                    <div class="flex-row mb-6">
                        <label class="block text-xs text-center mb-4"
                            >What type of home do you have?*</label
                        >
                        <select
                            v-model="typeOfHome"
                            class="mr-3 bg-white-alpha-3 block border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                            required
                        >
                            <option value="" selected="selected"
                                >Select One</option
                            >
                            <option value="option1">Options 1</option>
                            <option value="option2">Options 2</option>
                            <option value="option3">Options 3</option>
                            <option value="option4">Options 4</option>
                        </select>
                    </div>

                    <div class="flex-row mb-6">
                        <label class="block text-xs text-center mb-4"
                            >What many windows do you need installed?*</label
                        >
                        <select
                            v-model="numOfWindow"
                            class="mr-3 bg-white-alpha-3 block border w-full py-4 px-6 text-white leading-tight focus:outline-none focus:shadow-outline"
                            required
                        >
                            <option value="" selected="selected"
                                >Select One</option
                            >
                            <option value="option1">Options 1</option>
                            <option value="option2">Options 2</option>
                            <option value="option3">Options 3</option>
                            <option value="option4">Options 4</option>
                        </select>
                    </div>

                    <button
                        @click.prevent="postLead"
                        class="bg-primary text-white hover:bg-white hover:text-secondary text-center font-medium tracking-wider uppercase p-4 block w-full"
                    >
                        Request a free consultation
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupGetQuote",
    props: {
        dealer: {}
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            dateToBuy: "",
            typeOfHome: "",
            numOfWindow: "",
            dealer_id: this.dealer.id
        };
    },
    methods: {
        postLead() {
            console.log(this.dealer);
            var $this = this;
            axios
                .post("/lead", {
                    firstname: $this.firstName,
                    lastname: $this.lastName,
                    email: $this.email,
                    address1: $this.street,
                    phone: $this.phone,
                    state: $this.state,
                    zip: $this.zip,
                    dealer_id: $this.dealer.id
                })
                .then(
                    response => {
                        if (response.data.success) {
                            $this.$emit("close");
                            Swal.fire(
                                "Good job!",
                                "Your information has been sent!",
                                "success"
                            );
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        }
    }
};
</script>
