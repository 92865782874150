<template>
  <div class="section-1 bg-white relative z-10">
    <div class="container mx-auto py-32 px-6">
      <div class="lg:flex">
        <div class="flex-1 max-w-lg mx-auto mb-10 lg:m-0">
          <div class="yellow-bl-pattern">
            <div class="pattern"></div>
            <img src="./../../images/img1.png" />
          </div>
        </div>
        <div class="flex flex-1 lg:pl-12">
            <div class="flex-1 text-tertiary leading-loose text-sm type-text">
              <h2 class="text-primary">Impact-Resistant<br/>Windows and Doors:</h2>
              <p>
                These products protect your home from more than hurricane-force
                damage, they also guard your family against would-be intruders.
                The most common way to achieve impact resistance in windows
                and doors is to select a product that uses laminated glass or
                laminated insulating glass.</p>
              <p>
                Our impact-resistant laminated glass and laminated insulating
                glass are designed to withstand repeated impact from a
                nine-pound 2' x 4' beam traveling at 34 miles per hour. Even if the
                glass is damaged, the interlayer will hold the glass pieces secure in
                the frame and continue to keep unwanted elements outside.</p>
            </div>
            <div class="pl-6 w-48 lg:w-48">
              <img data-aos="fade-up-left" src="./../../images/img2.png" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Section1'
}
</script>
