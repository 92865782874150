import ChatBot from "../ChatBot";
import ChatBotBehavior from "../ChatBotBehavior";
import { gmapApi }  from "vue2-google-maps/dist/main";
import FurtherAssistanceBehaviorDecorator from "../BehaviorDecorators/FurtherAssistanceBehaviorDecorator";
import CreateLead from "../CreateLead";
import {v4 as uuid} from "uuid";
import PhoneNumberValidator from "../Validators/PhoneNumber";
import EmailValidator from "../Validators/EmailAddress";
import DateTimeValidator from "../Validators/DateTime";
import BrandQuestions from "../BehaviorDecorators/BrandQuestions";
import PatioPorchTransformer from "../Common/PatioPorchTransformer";

export default class {
    ChatBot = null;

    /**
     *
     * @param {ChatBot} ChatBotInstance
     */
    constructor(ChatBotInstance) {
        if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
        this.ChatBot = ChatBotInstance;
        this.initializeGoogleMaps();
        let $ChatBot = this.ChatBot;
        this.ChatBot.setInputTransformer('captures_window_amount', function(input){
            let preset = $ChatBot.getBinding('window_amount').find(e => e.name === input);
            return preset ? preset.id : input;
        });
        this.ChatBot.setInputTransformer('captures_door_amount', function(input){
            let preset = $ChatBot.getBinding('door_amount').find(e => e.name === input);
            return preset ? preset.id : input;
        });
        this.ChatBot.setInputTransformer('patio_porch_selected', PatioPorchTransformer.bind(this));
    }

    getBehaviorList() {
        let $this = this;
        return [
            new ChatBotBehavior('captures_name')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .whenEvents('flowStarted')
                .template('Great! I can help you with an in-home visit. Before we get started, I’ll need your first and last name.'),
            new ChatBotBehavior('captures_email')
                .setRenderer(this.ChatBot.renderer)
                .setValidator(EmailValidator)
                .captureInput()
                .whenEvents('message')
                .when(() => this.ChatBot.getLastUsedInputPointer().step_name === 'captures_name')
                .template('Thanks, {val:captures_name}. Next, I’ll need your email.'),
            new ChatBotBehavior('thanks_email')
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(() => this.ChatBot.getLastUsedInputPointer().step_name === 'captures_email')
                .template('Perfect, thank you.')
                .after(() => this.ChatBot.emit('botEcho')),
            new ChatBotBehavior('captures_allow_contact')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .whenEvents('botEcho')
                .when(() => this.ChatBot.getLastUsedInputPointer().step_name === 'captures_email')
                .template('We use email to learn more about your experience and provide you with the latest ' +
                    'information and materials for {binded:brand_name} products. Would you like us to contact you with updates ' +
                    'using this email' +
                    '<br /><button class="input-button" data-input-name="allow_contact" data-input-value="Yes">Yes</button>' +
                    '<button class="input-button" data-input-name="allow_contact" data-input-value="No">No</button>')
                .disableStandardInput(),
            new ChatBotBehavior('captures_phone')
                .setRenderer(this.ChatBot.renderer)
                .setValidator(PhoneNumberValidator)
                .setMaxLengthTo(10)
                .captureInput()
                .whenEvents('message')
                .when(() => this.ChatBot.getLastUsedInputPointer().step_name === 'captures_allow_contact')
                .template('Got it. What’s the best phone number to reach you at?'),
            new ChatBotBehavior('thanks_phone')
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(() => this.ChatBot.getLastUsedInputPointer().step_name === 'captures_phone')
                .after(() => this.ChatBot.emit('promptAddress'))
                .template('Thank you.'),
            new ChatBotBehavior('captures_address')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .whenEvents('promptAddress')
                .template('The last piece of info we’ll need to collect for your in-home visit is... ' +
                    'well, your home address. What is your address?')
                .afterInput(() => {
                    searchAddress(this.ChatBot.getLastUsedInputPointer(), function(successResponse, error, fullResult){
                        if (successResponse) {
                            let fullAddressFound = successResponse;
                            let streetName = fullResult.address_components.find(e => e.types.indexOf('route') > -1);
                            if (streetName) streetName = streetName.long_name;
                            let streetNumber = fullResult.address_components.find(e => e.types.indexOf('street_number') > -1);
                            if (streetNumber) streetNumber = streetNumber.long_name;
                            let address1 = null;
                            if (streetName && streetNumber) {
                                address1 = streetNumber + ' ' + streetName;
                            }
                            let city = fullResult.address_components.find(e => e.types.indexOf('locality') > -1);
                            if (city) city = city.long_name;
                            let state = fullResult.address_components.find(e => e.types.indexOf('administrative_area_level_1') > -1);
                            if (state) state = state.long_name;
                            let zip = fullResult.address_components.find(e => e.types.indexOf('postal_code') > -1);
                            if (zip) zip = zip.long_name;
                            let country = fullResult.address_components.find(e => e.types.indexOf('country') > -1);
                            if (country) country = country.long_name;
                            $this.ChatBot.setInput('captures_address', fullAddressFound);
                            $this.ChatBot.setInput('confirmed_full_address', {
                                address1: address1,
                                city: city,
                                state: state,
                                zip: zip,
                                country: country
                            });

                            $this.ChatBot.emit('addressFound');
                        } else {
                            $this.ChatBot.emit('badAddress');
                        }
                    });
                }),
            new ChatBotBehavior('bad_address_info')
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('badAddress')
                .template('Oops! Please re-enter your address, the same way you would if you were ordering something online.')
                .after(() => this.ChatBot.emit('promptAddress')),
            new ChatBotBehavior('confirms_address')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('addressFound')
                .template('We found {val:captures_address}. Is this your address?' +
                    '<br /><button class="input-button" data-input-name="confirms_address" data-input-value="Yes">Yes</button>' +
                    '<button class="input-button" data-input-name="confirms_address" data-input-value="No">No</button>'
                )
                .afterInput(input => {
                    if (input.toLowerCase() === 'yes') {
                        $this.ChatBot.emit('addressConfirmed');
                    }
                    if (input.toLowerCase() === 'no') {
                        $this.ChatBot.emit('badAddress');
                    }
                }),
            ...BrandQuestions.call(this),
            new ChatBotBehavior('info_dates')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('brandQuestionsCompleted')
                .template('Got it! Here’s what is going to happen next. You tell us what 3 different date and ' +
                    'time options, starting with the most ideal. We will pass that info along to {binded:dealer_name} and they will ' +
                    'reach out to confirm which date/time works for them, or work with you to select a different date.')
                .after(() => {setTimeout(() => { $this.ChatBot.emit('startDateSelection') }, 1300)}),
            new ChatBotBehavior('first_date')
                .setRenderer(this.ChatBot.renderer)
                .setValidator(DateTimeValidator)
                .captureInput()
                .disableStandardInput()
                .whenEvents('startDateSelection')
                .template('Please share your first, most ideal date and time.')
                .after(() => showDateTimePicker($this.ChatBot, $this.ChatBot.getLastUsedInputPointer(), ()=>{})),
            new ChatBotBehavior('second_date')
                .setRenderer(this.ChatBot.renderer)
                .setValidator(DateTimeValidator)
                .captureInput()
                .disableStandardInput()
                .whenEvents('message')
                .when(() => $this.ChatBot.getLastUsedInputPointer().step_name === 'first_date')
                .template('Fingers crossed that time works for {binded:dealer_name}! What’s your second choice?')
                .after(() => showDateTimePicker($this.ChatBot, $this.ChatBot.getLastUsedInputPointer(), ()=>{})),
            new ChatBotBehavior('third_date')
                .setRenderer(this.ChatBot.renderer)
                .setValidator(DateTimeValidator)
                .captureInput()
                .disableStandardInput()
                .whenEvents('message')
                .when(() => $this.ChatBot.getLastUsedInputPointer().step_name === 'second_date')
                .template('Got it. Remember, the farther out the date/time, the more likely {binded:dealer_name} ' +
                    'will have availability. That said, please select a third option.')
                .after(() => showDateTimePicker($this.ChatBot, $this.ChatBot.getLastUsedInputPointer(), ()=>{})),
            new ChatBotBehavior('captures_info')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .whenEvents('message')
                .when(() => $this.ChatBot.getLastUsedInputPointer().step_name === 'third_date')
                .template('Lastly, please tell us a bit about what you’re hoping to accomplish with this visit. ' +
                    'If there’s a gate code or any details {binded:dealer_name} needs to know to get to your home, ' +
                    'this is a good time to share that.'),
            new ChatBotBehavior('captures_info_2')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .whenEvents('message')
                .when(() => $this.ChatBot.getLastUsedInputPointer().step_name === 'captures_info')
                .template('Alright, {val:captures_name}! You’re all set. {binded:dealer_name} will reach out to you ' +
                    'confirming which time works best for them. To make the process easier for all parties, please make ' +
                    'sure the homeowner(s) are present for the in-home appointment.')
                .after(() => {
                    let full_name = this.ChatBot.getInput('captures_name');
                    let full_name_has_parts = full_name.indexOf(' ') > -1;
                    let first_name, last_name;
                    if (full_name_has_parts) {
                        first_name = full_name.substring(0, full_name.lastIndexOf(' '));
                        last_name  = full_name.substring(full_name.lastIndexOf(' ') + 1);
                    } else {
                        first_name = full_name;
                        last_name  = '';
                    }
                    let full_address = this.ChatBot.getInput('confirmed_full_address');
                    let lead = new CreateLead(this.ChatBot.parentChat.getDealer().id, this.ChatBot.parentChat.getBrand().id);
                    lead.setFirstname(first_name);
                    lead.setLastname(last_name);
                    lead.setAddress(full_address.address1);
                    lead.setCity(full_address.city);
                    lead.setState(full_address.state);
                    lead.setZip(full_address.zip);
                    lead.setCountry(full_address.country);
                    lead.setEmail(this.ChatBot.getInput('captures_email'));
                    lead.setPhone(this.ChatBot.getInput('captures_phone'));
                    lead.setInformation('appointment_type', 'in-home');
                    lead.setInformation('preferred_dates', [
                        this.ChatBot.getInput('first_date'),
                        this.ChatBot.getInput('second_date'),
                        this.ChatBot.getInput('third_date'),
                    ]);
                    if ([4].indexOf(this.ChatBot.parentChat.getBrand().id) > -1) {
                        lead.setInterestedProductsSelected(this.ChatBot.getInput('patio_porch_selected'));
                        lead.setPatioCover(this.ChatBot.getInput('captures_patio_covered'));
                        lead.setInstallation(this.ChatBot.getInput('captures_need_installation'));
                    } else {
                        lead.setInformation('door_amount', this.ChatBot.getInput('captures_door_amount'));
                        lead.setInformation('window_amount', this.ChatBot.getInput('captures_window_amount'));
                    }
                    lead.setInformation('additional', this.ChatBot.getInput('captures_info'));
                    lead.setInformation('is_appointment', true);
                    lead.setChatId(uuid());
                    lead.post();
                    $this.ChatBot.emit('promptFurtherAssistance')
                }),
            new FurtherAssistanceBehaviorDecorator(null, this.ChatBot).make()
        ];
    }

    initializeGoogleMaps() {
        let key = process.env.MIX_GMAP_API_KEY;

    }
}

function searchAddress(inputPointer, cb) {
    axios
        .post('/gmaps-proxy', {
            url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(inputPointer.input)
        })
        .then(r => {
            if (r && r.data && r.data.results) {
                let result = r.data.results[0];
                let formatted = result.formatted_address;
                cb(formatted, null, result);
            } else {
                handleError(r);
            }
        })
        .catch(e => {
            handleError(e)
            throw e
        });

    function handleError(e) {
        cb(null, e);
    }
}

function showDateTimePicker(ChatBotInstance, inputPointer, cb) {
    ChatBotInstance.parentChat.setDateTimeInput(function(date, time){
        cb();
    });
}
