<template>
    <div class="home">
        <div
            class="header bg-primary py-6"
            data-aos="fade-down"
            data-aos-once="true"
        >
            <div class="container mx-auto px-6">
                <div class="flex">
                    <div class="flex-1">
                        <img class="w-48" src="./../../images/logo.png" />
                    </div>

                    <a
                        class="flex items-center text-base leading-tight block"
                        href="tel:7346582456"
                    >
                        <span
                            class="mr-4 bg-secondary rounded-full text-white text-xl w-12 h-12 flex items-center justify-center"
                            ><i class="fas fa-phone-alt"></i
                        ></span>
                        <span class="text-secondary font-semibold text-xl block"
                            >{{ dealer.phone }}<br /><span
                                class="block text-white font-normal text-sm"
                                >CALL US NOW</span
                            ></span
                        >
                    </a>
                </div>
            </div>
        </div>
        <QuestionSlide
            :dealer="this.dealer"
            :service_types="this.service_types"
            :home_types="this.home_types"
            :new_buy_plans="this.new_buy_plans"
            :window_amount="this.window_amount"
            :door_amount="this.door_amount"
            :window_importance="this.window_importance"
            :interested_products="this.interested_products"
            :lead_types="this.lead_types"
            :business_types="this.business_types"
            :project_conditions="this.project_conditions"
            :project_types="this.project_types"
            :states="this.states"
            :homes_built_per_year="this.homes_built_per_year"
        />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 :dealer="this.dealer" />
        <div class="footer bg-primary relative z-10">
            <div class="container mx-auto pt-24 pb-10 px-6 text-center">
                <a href="/" class="inline-block max-w-xs"
                    ><img src="./../../images/logo.png"
                /></a>

                <ul
                    class="footer-social-icons flex flex-wrap justify-center text-2xl text-primary mt-8"
                >
                    <li class="p-2 mt-2">
                        <a
                            href="/"
                            class="bg-white hover:bg-secondary rounded-full w-12 h-12 flex items-center justify-center"
                            ><i class="fab fa-facebook-f"></i
                        ></a>
                    </li>
                    <li class="p-2 mt-2">
                        <a
                            href="/"
                            class="bg-white hover:bg-secondary rounded-full w-12 h-12 flex items-center justify-center"
                            ><i class="fab fa-twitter"></i
                        ></a>
                    </li>
                    <li class="p-2 mt-2">
                        <a
                            href="/"
                            class="bg-white hover:bg-secondary rounded-full w-12 h-12 flex items-center justify-center"
                            ><i class="fab fa-pinterest"></i
                        ></a>
                    </li>
                    <li class="p-2 mt-2">
                        <a
                            href="/"
                            class="bg-white hover:bg-secondary rounded-full w-12 h-12 flex items-center justify-center"
                            ><i class="fab fa-linkedin-in"></i
                        ></a>
                    </li>
                </ul>

                <ul
                    class="footer-menu-links flex flex-wrap justify-center text-sm font-medium uppercase mt-10 text-white"
                >
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                </ul>

                <ul
                    class="footer-menu-links2 flex flex-wrap justify-center text-2xs font-medium uppercase mt-6 text-white"
                >
                    <li class="px-3 mt-2">
                        &copy;2019 PGT&reg; Custom Windows + Doors. All rights
                        Reserved
                    </li>
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                    <li class="px-3 mt-2"></li>
                </ul>
            </div>
            <!-- <button
                class="bg-secondary text-primary hover:bg-yellow-600 hover:text-white text-center text-2xl font-bold uppercase p-4 block w-full shadow-2xl"
                @click="showPopup()"
            >
                Get a Quote
            </button> -->
        </div>
    </div>
</template>

<script>
import QuestionSlide from "./QuestionSlide.vue";
import Section1 from "./Section1.vue";
import Section2 from "./Section2.vue";
import Section3 from "./Section3.vue";
import Section4 from "./Section4.vue";
import PopupGetQuote from "./PopupGetQuote.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    name: "home",
    props: {
        dealer: {},
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount:{},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        states: {},
        homes_built_per_year: {},
    },
    components: {
        QuestionSlide,
        Section1,
        Section2,
        Section3,
        Section4,
        VueGoogleAutocomplete
    },
    methods: {
        showPopup() {
            this.$modal.show(PopupGetQuote, { dealer: this.dealer });
        }
    }
};
</script>
