<template>
<div>
    <section :class="full_wrapper_classes">
        <section class="home-banner">
            <img class="banner-image if-not-mobile" :src="bannerImage" />
            <div class="home-banner-form">
                <div class="search_steps">
                    <QuoteStepFormRedesign
                        :service_types="this.service_types"
                        :home_types="this.home_types"
                        :new_buy_plans="this.new_buy_plans"
                        :window_amount="this.window_amount"
                        :door_amount="this.door_amount"
                        :window_importance="this.window_importance"
                        :interested_products="this.interested_products"
                        :lead_types="this.lead_types"
                        :business_types="this.business_types"
                        :project_conditions="this.project_conditions"
                        :project_types="this.project_types"
                        :states="this.states"
                        :brand="this.brand"
                        :brand-name="this.brandName"
                        :source_id="1"
                        :brand_ga="this.brand_ga"
                        :interested_financing="this.interested_financing"
                        :redirect_thankyou="this.redirect_thankyou"
                        :countries="this.countries"
                        :default_country="this.default_country"
                        :data_form="this.data_form"
                        :homes_built_per_year="this.homes_built_per_year"
                    >
                    </QuoteStepFormRedesign>
                </div>
            </div>
            <img class="banner-image if-mobile" :src="bannerImage" />
            <div class="clearfix"></div>
        </section>
        <!-- /Banner Section -->
        <section class="wrapped-section section-content">
            <div class="clearfix">
                <div class="region-content">
                    <div class="region-content-inner">
                        <div class="search-header">
                            <div class="search-or">
                                Prefer to Do Your Own Research?
                            </div>
                            <p class="search-intro-text">
                                We sell exclusively through a dealer network who
                                can help you find the right products for your
                                home or property. To find an authorized dealer
                                in your area, please enter your zip code below.
                            </p>
                        </div>
                        <div class="search-wrapper clearfix">
                            <input type="hidden" v-model="params.latitude" />
                            <input type="hidden" v-model="params.longitude" />
                            <div class="search-fields">
                                <div class="search-field-wrapper position-relative clearfix">
                                    <div class="form-item">
                                        <div class="form-item form-type-textfield">
                                            <div class="label_small_bold">Enter your zipcode</div>
                                            <input
                                                class="form-text full-width-on-mobile"
                                                type="text"
                                                id=""
                                                v-model="params.zipcode"
                                                value=""
                                                maxlength="5"
                                                @input="sanitizeZipCode"
                                                @focusin="showUseMyLocationButton = true"
                                                @focusout="hideUseMyLocationButton()"
                                            />
                                        </div>
                                        <div
                                            class="use-my-location"
                                            v-if="showUseMyLocationButton == true"
                                            @click="useMyLocation">
                                            Use my location
                                        </div>
                                        <div
                                            class="form-item form-type-select"
                                            v-if="show_distance === 1"
                                        >
                                            <div class="label_small_bold">From</div>
                                            <select
                                                v-model="params.distance"
                                                class="form-select"
                                            >
                                                <option
                                                    v-for="(item,
                                                key) in this.distances"
                                                    :key="key"
                                                    :value="item.id"
                                                >{{
                                                        item.value
                                                    }}</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="label_small_bold mt-4 pt-4">Select the type of dealer you're looking for</div>
                                    <div class="select-dealer-left">
                                        <div class="form-item">
                                            <label
                                                class="form-item form-type-checkbox"
                                                v-if="!params.international"
                                            >
                                                <input
                                                    class="form-checkbox"
                                                    type="checkbox"
                                                    v-model="params.showroom"
                                                />
                                                <span
                                                    class="form-checkbox-label"
                                                ></span>
                                                Dealers with Showrooms
                                            </label>

                                            <label
                                                class="form-item form-type-checkbox"
                                                v-if="!params.international"
                                            >
                                                <input
                                                    class="form-checkbox"
                                                    type="checkbox"
                                                    value="R"
                                                    v-model="params.primary_segment"
                                                />
                                                <span
                                                    class="form-checkbox-label"
                                                ></span>
                                                Dealers for homeowners
                                            </label>
                                        </div>
                                    </div>
                                    <div class="select-dealer-right break-on-mobile">
                                        <div class="form-item">
                                            <label
                                                class="form-item form-type-checkbox"
                                                v-if="!params.international"
                                            >
                                                <input
                                                    class="form-checkbox"
                                                    type="checkbox"
                                                    value="B"
                                                    v-model="params.primary_segment"
                                                />
                                                <span
                                                    class="form-checkbox-label"
                                                ></span>
                                                Dealers for Trade/Industry
                                                Professionals
                                            </label>

                                            <label
                                                class="form-item form-type-checkbox"
                                            >
                                                <input
                                                    class="form-checkbox"
                                                    type="checkbox"
                                                    v-model="params.international"
                                                />
                                                <span
                                                    class="form-checkbox-label"
                                                ></span>
                                                Dealers for International/Export
                                            </label>
                                        </div>
                                    </div>
                                    <div v-show="retailers_enabled == '1'" class="select-dealer-right break-on-mobile">
                                        <div class="form-item">
                                            <label
                                                class="form-item form-type-checkbox"
                                                v-if="!params.international"
                                            >
                                                <input
                                                    class="form-checkbox"
                                                    type="checkbox"
                                                    value="B"
                                                    v-model="params.retailers"
                                                />
                                                <span
                                                    class="form-checkbox-label"
                                                ></span>
                                                Home Center/Retail
                                            </label>
                                        </div>
                                    </div>
                                    <div class="Clearfix"></div>

                                </div>
                            </div>

                            <div class="search-fields mt-5">
                                <label>&nbsp;</label>
                                <input
                                    class="form-submit common-btn"
                                    type="submit"
                                    value="Submit"
                                    @click="submitSearch()"
                                />
                            </div>
                        </div>
                        <div
                            class="results-wrapper clearfix"
                            v-if="show_results == 'loading'"
                        >
                            <p>Please wait! Fetching your results.</p>
                        </div>

                        <div
                            class="results-wrapper clearfix"
                            v-if="show_results == 'loading-auto'"
                        >
                            <p>Please wait! Loading results based on your current location.</p>
                        </div>

                        <div
                            class="results-wrapper clearfix"
                            v-else-if="show_results == 'yes'"
                        >
                            <div
                                class="result-wrapper-container"
                                v-if="dealers.length > 0"
                            >
                                <div class="dealer-locator-results">
                                    <slick ref="slick" :options="slickOptions">
                                        <div
                                            class="results-row clearfix"
                                            v-for="dealer in dealers"
                                        >
                                            <div class="results-left">
                                                <div
                                                    class="field-title uppercase"
                                                >
                                                    {{ dealer.name }}
                                                </div>
                                                <div
                                                    v-if="dealer.distance"
                                                    class="field_geofield_distance"
                                                >
                                                    Distance:
                                                    {{
                                                        dealer.distance.toFixed(
                                                            2
                                                        )
                                                    }}
                                                    miles
                                                </div>
                                                <div
                                                    class="views-field-field_dealer_address"
                                                >
                                                    <div
                                                        class="dealer-locator-address"
                                                    >
                                                        <div
                                                            class="dealer-locator-address1"
                                                        >
                                                            {{
                                                                dealer.address1
                                                            }}
                                                        </div>
                                                        <div
                                                            class="dealer-locator-address2"
                                                        >
                                                            {{
                                                                dealer.address2
                                                            }}
                                                        </div>
                                                        <div
                                                            class="dealer-locator-city"
                                                        >
                                                            {{ dealer.city }},
                                                            {{ dealer.state }}
                                                            {{ dealer.zip }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dealer_telephone">
                                                    <a
                                                        :href="
                                                            dealer.call_rail_number
                                                                ? 'tel:' +
                                                                  dealer.call_rail_number
                                                                : 'tel:' +
                                                                  dealer.phone
                                                        "
                                                    >
                                                        {{
                                                            dealer.call_rail_number
                                                                ? dealer.call_rail_number
                                                                : dealer.phone
                                                        }}
                                                    </a>
                                                </div>
                                                <div
                                                    class="dealer_finance"
                                                    v-show="dealer.finance"
                                                >
                                                    <img
                                                        src="/images/PGT_Financing_Badge.gif"
                                                        class="finance_badge"
                                                        v-if="brand == 1"
                                                    />
                                                    <img
                                                        src="/images/CGI_Financing_Badge.gif"
                                                        class="finance_badge"
                                                        v-if="brand == 2"
                                                    />
                                                    <img
                                                        src="/images/WinDoor_Financing_Badge.gif"
                                                        class="finance_badge"
                                                        v-if="brand == 3"
                                                    />
                                                    <img
                                                        src="/images/EzeBreeze_Financing_Badge.gif"
                                                        class="finance_badge"
                                                        v-if="brand == 4"
                                                    />
                                                    <img
                                                        src="/images/Eco_Financing_Badge.gif"
                                                        class="finance_badge"
                                                        v-if="brand == 6"
                                                    />
                                                </div>
                                                <!-- <div
                                                    class="dealer_weburl_approved"
                                                >
                                                    <a
                                                        :href="
                                                            'https://' +
                                                                dealer.subdomain +
                                                                '.' +
                                                                domain
                                                        "
                                                        target="_blank"
                                                        >Details</a
                                                    >
                                                </div> -->
                                            </div>
                                            <div class="results-right">
                                                <div class="dealer_etp_status">
                                                    <a
                                                        :href="
                                                            mapSelector +
                                                                '://maps.google.com/maps?q=' +
                                                                dealer.address1 +
                                                                ' ' +
                                                                dealer.city +
                                                                ' ' +
                                                                dealer.state +
                                                                ' ' +
                                                                dealer.zip
                                                        "
                                                        class="text-sm button common-holo-btn"
                                                        target="_blank"
                                                    >
                                                        Directions
                                                    </a>
                                                    <br />
                                                    <button
                                                        v-if="
                                                            dealer.show_quote_button
                                                        "
                                                        class="text-sm button common-holo-btn"
                                                        @click="
                                                            showQuotePopup(
                                                                dealer
                                                            )
                                                        "
                                                    >
                                                        Get Quote
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="location-border"></div>
                                        </div>
                                    </slick>
                                </div>
                                <div class="dealer-locator-map">
                                    <div class="attachment attachment-before">
                                        <google-map
                                            :dealers="dealers"
                                        ></google-map>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h5 class="no-results-title">
                                    A dealer is not available in this area. Please complete one of the following steps to locate a dealer:
                                </h5>
                                <ol class="no-results-list">
                                    <li>Try a different zip code.</li>
                                    <li>Select additional dealer types.</li>
                                    <li><a href="#app">Fill out our form, and a dealer will contact you directly.</a></li>
                                </ol>
                            </div>
                        </div>
                        <div class="results-wrapper clearfix" v-else></div>
                    </div>
                </div>
            </div>
        </section>
    </section>

</div>
</template>

<style scoped>
.use-my-location {
    position: absolute;
    width: 303px;
    height: 30px;
    background-color: #6da947;
    color: #FFFFFF;
    text-align: center;
    top: 62px;
    text-transform: uppercase;
    padding: 3px;
    cursor: pointer;
    max-width: 213px;
}

@media(max-width: 991px) {
    .full-width-on-mobile {
        max-width: unset;
        width: 100%;
    }
    .use-my-location {
        top: 55px;
        height: 40px;
        padding-top: 15px;
        width: 100%;
        max-width: unset;
    }
}

.position-relative {
    position: relative;
}
.finance_badge {
    width: 120px;
    margin-top: 10px;
}
.form_section {
    min-height: 0;
}
.step_form_main {
    padding: 25px 32px 65px;
}
.step_form_main.step_two {
    padding-top: 30px;
}
.button_cont .custom_btn {
    width: 10rem;
}
.dealer_weburl_approved a {
    display: block;
}

.button_cont button {
    font-weight: 400;
    width: 100%;
    border-width: 0;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #f6b221;
    color: #003865;
    height: 57px;
    font-size: 21px;
    font-family: inherit;
}

.button_cont button.prev_btn {
    float: left;
    font-weight: 300;
    background-color: #003865;
    color: #f6b221;
}

.dealer_etp_status button,
.dealer_etp_status a {
    width: 150px;
}
.dealer_etp_status a {
    padding-top: 10px;
}
.dealer-locator-results {
    float: left;
}
@media only screen and (max-width: 991px) {
    .finance_badge {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}
</style>

<script>
import * as VueGoogleMaps from "vue2-google-maps";
let VueScrollTo = require("vue-scrollto");

import Slick from "vue-slick";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GMAP_API_KEY,
        libraries: "places",
        components: "country:us"
    },
    installComponents: true
});

import GoogleMap from "./GoogleMap";
import QuoteStepFormRedesign from "./QuoteStepFormRedesign.vue";

export default {
    name: "FindDealerECO",
    data() {
        return {
            dealers: {},
            mapSelector: "https",
            show_results: "none",
            show_distance: 1,
            search_status: 0,
            bannerImage: "assets/images/banner-eco.png",
            brandName: "PGT",
            data_form: "shared",
            params: {
                zipcode: "",
                distance: 15,
                latitude: 0.0,
                longitude: 0.0,
                type: 1,
                showroom: false,
                primary_segment: ["R"],
                brand: this.brand,
                international: false,
                retailers: false
            },
            distances: this.distanceOptions(),
            has_errors: false,
            domain: process.env.MIX_APP_DOMAIN,
            slickOptions: {
                slidesToScroll: 1,
                vertical: true,
                infinite: false
            },
            showUseMyLocationButton: false
        };
    },
    props: {
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount: {},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        coi_dealers: {},
        states: {},
        brand: "",
        brand_ga: "",
        iframe_domain: "",
        interested_financing: "",
        redirect_thankyou: "",
        countries: {},
        default_country: "",
        retailers_enabled: "",
        append_classes: "",
        homes_built_per_year: "",
    },
    components: {
        GoogleMap,
        QuoteStepFormRedesign,
        Slick
    },
    created() {
        if (this.brand == 2) {
            this.bannerImage = "assets/images/banner_cgi.jpg";
            this.brandName = "CGI";
        }
        if (this.brand == 3) {
            this.bannerImage = "assets/images/banner_windoor.jpg";
            this.brandName = "WinDoor";
        }
        if (this.brand == 4) {
            this.bannerImage = "assets/images/banner_eze.jpg";
            this.brandName = "Eze Breeze";
        }
    },
    mounted() {
        //this.initApiToken();
        //this.geoLocationRequest();
        if (
            navigator.platform.indexOf("iPhone") != -1 ||
            navigator.platform.indexOf("iPad") != -1
        ) {
            this.mapSelector = "maps";
        }
    },
    methods: {
        hideUseMyLocationButton() {
            setTimeout(() => {
                this.showUseMyLocationButton = false;
            }, 200);
        },
        useMyLocation() {
          this.getUserZipCode();
        },
        sanitizeZipCode() {
            this.params.zipcode = this.params.zipcode.replace(/\D/g, '');
        },
        submitSearch() {
            if (this.search_status === 0) {
                if (this.params.international) {
                    this.searchDealers(1);
                } else {
                    this.fetchLatLong();
                }
            } else if (this.search_status === 1) {
                this.searchDealers(1);
            }
        },
        getUserZipCode() {
            if (!google) {
                return;
            }
            var _t = this;
            navigator.geolocation.getCurrentPosition(position => {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;
                // Auto load results for lat and long
                this.params.latitude = lat;
                this.params.longitude = lng;
                this.show_results = 'loading-auto';
                this.searchDealers(1);
                var resultsWrapperElements = this.$el.getElementsByClassName('results-wrapper');
                if (resultsWrapperElements[0]) {
                    resultsWrapperElements[0].scrollIntoView({behavior: 'smooth'});
                }

                // Nevertheless, try to load the user zipcode derived from the data we just got
                // and place it in the input, at least for the user's information
                var point = new google.maps.LatLng(lat, lng);
                new google.maps.Geocoder().geocode({
                        'latLng': point
                    },
                    function(res, status){
                        if (status === "OK") {
                            // Try to get the zipcode from the first result that allows to do so
                            for (var i = 0; i < res.length; i++) {
                                var address = res[i].formatted_address;
                                var zip = address.match(/,\s\w{2}\s(\d{5})/);
                                if (zip && zip[1]){
                                    _t.params.zipcode = zip[1];
                                    break;
                                }
                            }
                        }
                    })
            });
        },
        fetchLatLong() {
            if (!this.params.zipcode) {
                this.has_errors = true;
                return true;
            }

            this.show_results = "loading";

            window.axios.defaults.headers.common = {
                Accept: "application/json",
                "Content-Type": "application/json"
            };

            var map_url =
                "https://maps.googleapis.com/maps/api/geocode/json?key=" +
                process.env.MIX_GMAP_API_KEY +
                "&components=postal_code:" +
                this.params.zipcode;
            axios.get(map_url).then(
                response => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0) {
                            var location =
                                response.data.results[0].geometry.location;
                            this.params.latitude = location.lat;
                            this.params.longitude = location.lng;
                            this.searchDealers(1);
                        } else {
                            this.show_results = "none";
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        searchDealers(search_type) {
            this.params.type = search_type;

            axios.post("/api/dealer/search", this.params).then(
                response => {
                    if (response.data.success && response.data.dealers) {
                        this.dealers = response.data.dealers;
                        this.show_results = "yes";
                        this.show_distance = search_type == 1 ? 1 : 0;
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        distanceOptions() {
            var distance = [
                { id: 15, value: "15 Miles" },
                { id: 20, value: "20 Miles" },
                { id: 25, value: "25 Miles" },
                { id: 30, value: "30 Miles" },
                { id: 50, value: "50 Miles" },
                { id: 100, value: "100 Miles" }
            ];

            return distance;
        },
        showQuotePopup(dealer) {
            this.$modal.show(
                QuoteStepFormRedesign,
                {
                    dealer: dealer,
                    brand: this.brand,
                    brandName: this.brandName,
                    brand_ga: this.brand_ga,
                    domain: this.domain,
                    service_types: this.service_types,
                    home_types: this.home_types,
                    new_buy_plans: this.new_buy_plans,
                    window_amount: this.window_amount,
                    door_amount: this.door_amount,
                    window_importance: this.window_importance,
                    interested_products: this.interested_products,
                    lead_types: this.lead_types,
                    business_types: this.business_types,
                    project_conditions: this.project_conditions,
                    project_types: this.project_types,
                    states: this.states,
                    show_close: true,
                    interested_financing: this.interested_financing,
                    redirect_thankyou: this.redirect_thankyou,
                    countries: this.countries,
                    default_country: this.default_country,
                    data_form: 'unique',
                    homes_built_per_year: this.homes_built_per_year,
                },
                {
                    height: "auto",
                    clickToClose: false,
                    name: "quote_modal"
                }
            );

            setTimeout(() => {
                parent.postMessage({ task: "scroll_top" }, "*");
            }, 50);
        },
        showBusinessPopup() {
            this.$modal.show(BusinessContact);
        },
        initApiToken() {
            axios
                .post("/api/token/request", {
                    dealer_id: "general",
                    secret: "general"
                })
                .then(
                    response => {
                        if (response.data.success) {
                            console.log(response.data.message);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        },
        geoLocationRequest() {
            // var $this = this;
            // var geoSuccess = function(position) {
            //     $this.params.latitude = position.coords.latitude;
            //     $this.params.longitude = position.coords.longitude;
            //     $this.searchDealers(0);
            // };
            // var geoError = function(error) {
            //     console.log(
            //         "Error occurred. Error code: " +
            //             error.code +
            //             ", Error message: " +
            //             error.message
            //     );
            // };
            //navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        },
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        }
    },
    computed: {
        platinum() {
            return _.filter(this.coi_dealers, { level: 3 });
        },
        diamond() {
            return _.filter(this.coi_dealers, { level: 2 });
        },
        gold() {
            return _.filter(this.coi_dealers, { level: 1 });
        },
        full_wrapper_classes() {
            let res = 'wtb-wrapper';
            if (this.append_classes) {
                res += ' ' + this.append_classes;
            }
            return res;
        }
    },
    watch: {
        "params.international": function(val) {
            if (val) {
                this.params.primary_segment = [];
            } else {
                this.params.primary_segment = ["R"];
            }
        }
    }
};
</script>
