<template>
    <section :class="full_wrapper_classes">
        <plugin-preloader :brand_loading_hex_color="brand_loading_hex_color"></plugin-preloader>
        <!-- Banner Section -->
        <section class="home-banner" :class="brand !== 2 && brand !== 3 && brand !== 6 ? `font-${brandPrimaryFontFamily}` : ''">
            <img class="banner-image if-not-mobile" :src="url_prepend + bannerImage" />
            <div class="home-banner-form">
                <div class="search_steps">
                    <QuoteStepFormRedesign
                        :service_types="this.service_types"
                        :home_types="this.home_types"
                        :new_buy_plans="this.new_buy_plans"
                        :window_amount="this.window_amount"
                        :door_amount="this.door_amount"
                        :window_importance="this.window_importance"
                        :interested_products="this.interested_products"
                        :lead_types="this.lead_types"
                        :business_types="this.business_types"
                        :project_conditions="this.project_conditions"
                        :project_types="this.project_types"
                        :states="this.states"
                        :brand="this.brand"
                        :brand-name="this.brandName"
                        :brand_info="this.brand_info"
                        :source_id="1"
                        :brand_ga="this.brand_ga"
                        :interested_financing="this.interested_financing"
                        :redirect_thankyou="this.redirect_thankyou"
                        :countries="this.countries"
                        :default_country="this.default_country"
                        :data_form="this.data_form"
                        :frontend_url="this.frontend_url"
                        :client_ip="this.client_ip"
                        :user_agent="this.user_agent"
                        :homes_built_per_year="this.homes_built_per_year"
                    >
                    </QuoteStepFormRedesign>
                </div>
            </div>
            <img class="banner-image if-mobile" :src="url_prepend + bannerImage" />
            <div class="clearfix"></div>
        </section>

      <dealer-locator
        :brand="this.brand"
        :brand-object="this.brand_info"
        :brandName="this.brandName"
        :brand_ga="this.brand_ga"
        :business_types="this.business_types"
        :client_ip="this.client_ip"
        :countries="this.countries"
        :data_form="this.data_form"
        :default_country="this.default_country"
        :door_amount="this.door_amount"
        :frontend_url="this.frontend_url"
        :full_wrapper_classes="this.full_wrapper_classes"
        :home_types="this.home_types"
        :homes_built_per_year="this.homes_built_per_year"
        :iframe_domain="this.iframe_domain"
        :interested_financing="this.interested_financing"
        :interested_products="this.interested_products"
        :lead_types="this.lead_types"
        :new_buy_plans="this.new_buy_plans"
        :project_conditions="this.project_conditions"
        :project_types="this.project_types"
        :redirect_thankyou="this.redirect_thankyou"
        :service_types="this.service_types"
        :states="this.states"
        :user_agent="this.user_agent"
        :window_amount="this.window_amount"
        :window_importance="this.window_importance"
        :brand_subdomain="this.brand_subdomain"
        :retailers_enabled="retailers_enabled"
      ></dealer-locator>

      <!--  END DYO Research -->
        <!-- EXCELLENCE THROUGH PARTNERSHIP -->
        <div class="border-t pt-32 pb-12 lg:pt-16 lg:pb-16 w-full" :style="`background-color: ${brandBgColor}`" v-if="[1, 2, 3, 4].indexOf(brand_info.id) > -1"> <!-- https://app.clickup.com/t/1z08r89?comment=632268480 -->
            <div class="max-w-5xl px-6 mx-auto" id="etp-section">

                <div>
                    <img v-if="brand_info.id === 1" :src="url_prepend + '/assets/images/ETP_cursive_1.png'" class="m-auto"
                         style="max-height: 150px;" alt=""/>
                    <img v-if="brand_info.id === 2" :src="url_prepend + '/assets/images/ETP_cursive_2.png'" class="m-auto"
                         style="max-height: 150px;" alt=""/>
                    <img v-if="brand_info.id === 6" :src="url_prepend + '/assets/images/ETP_cursive_6.png'" class="m-auto"
                         style="max-height: 150px;" alt=""/>
                    <img v-if="brand_info.id === 4" :src="url_prepend + '/assets/images/eze/ezb_etp_badge.png'" class="m-auto"
                         style="max-height: 150px;" alt=""/>
                    <img v-if="brand_info.id === 7" :src="url_prepend + '/assets/images/eze/anlin_etp_badge.png'" class="m-auto"
                         style="max-height: 150px;" alt=""/>
                </div>
                <h2
                    class="etp-header
            lg:text-4xl
            text-3xl
            font-bold
            text-center
            pt-10
            pb-6
          " :style="'color: ' + brandETPTitleColorHex"
                >
                    Excellence Through Partnership
                </h2>
                <p
                    class="
            md:max-w-4xl
            lg:text-xl
            md:text-lg
            text-sm
            w-full
            text-center
            m-auto
            text-gray-600
            etp-copy
          "
                >
                    Each year, we honor exceptional dealers who have showcased the highest
                    dedication to serving their communities with products that are built for both the everyday and the once in a
                    lifetime.
                </p>
                <br/>
                <p
                    class="lg:text-xl md:text-lg text-sm text-center m-auto text-gray-600"
                >
                    This program is divided into three tiers: Diamond, Platinum, and Gold.
                </p>
                <div class="items-center pt-20 pb-7">
                    <div class="flex flex-wrap">
                        <div class="w-full md:w-1/4">
                            <h3
                                :data-forcestyle="`tier-heading_${brand_info.id}`"
                                class="
                                  md:text-right
                                  text-center
                                  lg:text-2xl
                                  md:text-xl
                                  text-lg
                                  p-3
                                  mb-2
                                  cursor-pointer
                                  hover:bg-gray-50
                                  font-bold
                                  text-gray-400
                                  tier-heading
                                "
                                                v-bind:class="{
                                  'bg-gray-50 is-active-tier': isActive == 'Diamond',
                                }" :style="isActive == 'Diamond' ? `color: ${brandPrimaryColorHex}` : ''"
                                @click="selectTab('Diamond')"
                            >
                                Diamond Dealers
                            </h3>
                            <h3
                              :data-forcestyle="`tier-heading_${brand_info.id}`"
                                class="
                  md:text-right
                  text-center
                  lg:text-2xl
                  md:text-xl
                  text-lg
                  p-3
                  mb-2
                  cursor-pointer
                  hover:bg-gray-50
                  font-bold
                  text-gray-400
                  tier-heading
                "
                                v-bind:class="{
                  'bg-gray-50 is-active-tier': isActive == 'Platinum',
                }" :style="isActive == 'Platinum' ? `color: ${brandPrimaryColorHex}` : ''"
                                @click="selectTab('Platinum')"
                            >
                                Platinum Dealers
                            </h3>
                            <h3
                              :data-forcestyle="`tier-heading_${brand_info.id}`"
                                class="
                  md:text-right
                  text-center
                  lg:text-2xl
                  md:text-xl
                  text-lg
                  p-3
                  mb-2
                  cursor-pointer
                  hover:bg-gray-50
                  font-bold
                  text-gray-400
                  tier-heading
                "
                                v-bind:class="{
                  'bg-gray-50 is-active-tier': isActive == 'Gold',
                }" :style="isActive == 'Gold' ? `color: ${brandPrimaryColorHex}` : ''"
                                @click="selectTab('Gold')"
                            >
                                Gold Dealers
                            </h3>
                        </div>

                        <div
                            class="w-full md:w-3/4 flex flex-wrap items-start etp-dealer-list"
                            v-if="isActive === 'Gold'"
                        >
                            <div
                                v-for="(dealer, index) in gold"
                                :key="index"
                                class="
                  text-gray-600 md:text-sm
                  w-1/2
                  sm:w-1/3
                  lg:w-1/4
                  p-3
                  text-center
                "
                            >
                                {{ dealer.name }}
                            </div>
                        </div>
                        <div
                            class="w-full md:w-3/4 flex flex-wrap items-start etp-dealer-list"
                            v-if="isActive === 'Platinum'"
                        >
                            <div
                                v-for="(dealer, index) in platinum"
                                :key="index"
                                class="
                  text-gray-600 md:text-sm
                  w-1/2
                  sm:w-1/3
                  lg:w-1/4
                  p-3
                  text-center
                "
                            >
                                {{ dealer.name }}
                            </div>
                        </div>
                        <div
                            class="w-full md:w-3/4 flex flex-wrap items-start etp-dealer-list"
                            v-if="isActive === 'Diamond'"
                        >
                            <div
                                v-for="(dealer, index) in diamond"
                                :key="index"
                                class="
                  text-gray-600 md:text-sm
                  w-1/2
                  sm:w-1/3
                  lg:w-1/4
                  p-3
                  text-center
                "
                            >
                                {{ dealer.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- END ETP -->
        <!--
        <section id="excellenceThroughPartnership" class="wrapped-section circle-wrapper" :class="`font-${brandPrimaryFontFamily}`" v-else-if="brand.id !== 7">
            <div class="circle-wrapper-left break-on-mobile">
                <div class="section-circle">
                    <div class="circle-title-left">
                        <h3 :class="{ 'font-mont': brand == 6 }">Excellence Through Partnership</h3>
                    </div>
                    <div class="circle-description-left">
                        Each year, we honor exceptional dealers who have showcased the highest dedication to serving
                        their communities with products that are built for the everyday and the once-in-a-lifetime.
                        This program, Excellence Through Partnership, is divided into three tiers: Gold, Platinum, and Diamond.
                    </div>
                </div>

                <div>
                    <div>
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Gold Dealers</option>
                                <option value="A BETTER VIEW OF BREVARD"
                                >A BETTER VIEW OF BREVARD</option
                                >
                                <option value="A L THOMPSON"
                                >A L THOMPSON</option
                                >
                                <option value="BOCA IMPACT WINDOW AND DOOR"
                                >BOCA IMPACT WINDOW AND DOOR</option
                                >
                                <option value="CARROLLWOOD WINDOW & DOOR"
                                >CARROLLWOOD WINDOW & DOOR</option
                                >
                                <option value="CLEARVIEW WINDOWS & DOORS, INC"
                                >CLEARVIEW WINDOWS & DOORS, INC</option
                                >
                                <option value="CLEARWATER WINDOW & DOOR"
                                >CLEARWATER WINDOW & DOOR</option
                                >
                                <option value="CROW INDUSTRIES"
                                >CROW INDUSTRIES</option
                                >
                                <option value="D'S COASTAL WINDOWS INC"
                                >D'S COASTAL WINDOWS INC</option
                                >
                                <option value="DALY ALUMINUM"
                                >DALY ALUMINUM</option
                                >
                                <option value="DOVI WINDOW DESIGNS"
                                >DOVI WINDOW DESIGNS</option
                                >
                                <option value="FLORIDA SOLAR & AIR"
                                >FLORIDA SOLAR & AIR</option
                                >
                                <option value="FORTRESS WINDOWS"
                                >FORTRESS WINDOWS</option
                                >
                                <option value="GUARDIAN HURRICANE PROTECTION"
                                >GUARDIAN HURRICANE PROTECTION</option
                                >
                                <option value="HBS INC"
                                >HBS INC</option
                                >
                                <option value="KIMAL LUMBER COMPANY"
                                >KIMAL LUMBER COMPANY</option
                                >
                                <option value="MULLET'S ALUMINUM PRODUCTS INC"
                                >MULLET'S ALUMINUM PRODUCTS INC</option
                                >
                                <option value="NSI WINDOWS & DOORS, INC"
                                >NSI WINDOWS & DOORS, INC</option
                                >
                                <option
                                    value="R L JAMES, INC"
                                >R L JAMES, INC</option
                                >
                                <option value="SEAVIEW BUILDING SOLUTIONS"
                                >SEAVIEW BUILDING SOLUTIONS</option
                                >
                                <option value="SOUTHEASTERN SASH & DOOR"
                                >SOUTHEASTERN SASH & DOOR</option
                                >
                                <option value="SUN WINDOW & DOOR"
                                >SUN WINDOW & DOOR</option
                                >
                                <option value="TITAN WINDOW AND DOOR"
                                >TITAN WINDOW AND DOOR</option
                                >
                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Platinum Dealers</option>
                                <option value="ALL AMERICAN SHUTTERS & GLASS, INC"
                                >ALL AMERICAN SHUTTERS & GLASS, INC</option
                                >
                                <option value="AMAZING EZ-SCREEN PORCH WDWS"
                                >AMAZING EZ-SCREEN PORCH WDWS</option
                                >
                                <option value="ATLANTIC WINDOWS & SHUTTERS"
                                >ATLANTIC WINDOWS & SHUTTERS</option
                                >
                                <option value="BAHAMA GLASS & WINDOW INC"
                                >BAHAMA GLASS & WINDOW INC</option
                                >
                                <option value="BAYWEST GLASS & GLAZING INC"
                                >BAYWEST GLASS & GLAZING INC</option
                                >
                                <option value="COMPLETE ALUMINUM"
                                >COMPLETE ALUMINUM</option
                                >
                                <option value="DOUGLAS MANUFACTURING"
                                >DOUGLAS MANUFACTURING</option
                                >
                                <option value="ENGLEWOOD GLASS & MIRROR &AUTO"
                                >ENGLEWOOD GLASS & MIRROR &AUTO</option
                                >
                                <option
                                    value="FLORIDA WINDOWS & DOORS"
                                >FLORIDA WINDOWS & DOORS</option
                                >
                                <option value="GENERAL GLASS & WINDOW INC"
                                >GENERAL GLASS & WINDOW INC</option
                                >
                                <option value="JVR IMPACT WINDOWS & DOORS"
                                >JVR IMPACT WINDOWS & DOORS</option
                                >
                                <option value="KAMRELL WINDOWS & DOORS"
                                >KAMRELL WINDOWS & DOORS</option
                                >
                                <option value="LOUMAC DISTRIBUTORS"
                                >LOUMAC DISTRIBUTORS</option
                                >
                                <option value="NAPLES GLASS & ALUMINUM INC"
                                >NAPLES GLASS & ALUMINUM INC</option
                                >
                                <option value="ROYAL PALM ALUMINUM INC"
                                >ROYAL PALM ALUMINUM INC</option
                                >
                                <option value="SOUTHERN EXPOSURE SUNROOMS"
                                >SOUTHERN EXPOSURE SUNROOMS</option
                                >
                                <option value="WEATHER TITE WINDOWS"
                                >WEATHER TITE WINDOWS</option
                                >
                                <option value="WEATHERWALL ENCLOSURES SYSTEMS"
                                >WEATHERWALL ENCLOSURES SYSTEMS</option>

                            </select>
                        </div>
                    </div>
                    <div>
                        <div class="form-type-select">
                            <select class="form-select circle-select-option">
                                <option>Diamond Dealers</option>
                                <option value="ALL GLASS & WINDOWS"
                                >ALL GLASS & WINDOWS</option
                                >
                                <option value="BNT SERVICES">BNT SERVICES</option>

                                <option value="CARIBBEAN IMPACT WINDOWS & DOORS"
                                >CARIBBEAN IMPACT WINDOWS & DOORS</option
                                >
                                <option value="DIAMOND GLASS & ALUMINUM"
                                >DIAMOND GLASS & ALUMINUM</option
                                >
                                <option value="DIRECT WINDOW & DOOR"
                                >DIRECT WINDOW & DOOR</option
                                >
                                <option value="M&W WINDOWS"
                                >M&W WINDOWS</option
                                >
                                <option value="NAPLES LUMBER & SUPPLY"
                                >NAPLES LUMBER & SUPPLY</option
                                >
                                <option value="O'DONNELL IMPACT WINDOWS & STORM PROTECTION"
                                >O'DONNELL IMPACT WINDOWS & STORM PROTECTION</option
                                >
                                <option value="PRESTIGE WINDOWS"
                                >PRESTIGE WINDOWS</option
                                >
                                <option value="R Q BUILDING PRODUCTS INC"
                                >R Q BUILDING PRODUCTS INC</option
                                >
                                <option value="SARASOTA GLASS & MIRROR"
                                >SARASOTA GLASS & MIRROR</option
                                >
                                <option value="SCREENBUILDERS"
                                >SCREENBUILDERS</option
                                >
                                <option value="SUNCOAST CONTRACTORS SUPPLY"
                                >SUNCOAST CONTRACTORS SUPPLY</option
                                >
                                <option value="THE GLASS PROFESSIONALS INC"
                                >THE GLASS PROFESSIONALS INC</option
                                >
                                <option value="VERO GLASS & MIRROR"
                                >VERO GLASS & MIRROR</option
                                >

                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="circle-wrapper-right">
                <div class="circle-background-image">
                    <img :src="full_etp_background_image" />
                </div>
                <div class="circle-badge">
                    <img :src="url_prepend + circleImage" />
                </div>
            </div>
            <div class="clearfix"></div>
        </section>
        -->
        <!--
        <section class="footer-wrapper">
            <div class="wrapped-section">
                <div class="footer-top-layer">
                    <div class="footer-table">
                        <div class="footer-table-cel-left">
                            <img src="assets/images/logo_footer.png" />
                        </div>
                        <div class="footer-table-cell-middle">
                            <ul>
                                <li>Tools & resources</li>
                                <li>Contact</li>
                                <li>Privacy Policy</li>
                                <li>Become a dealer</li>
                            </ul>
                        </div>
                        <div class="footer-table-cell-right">
                            <ul>
                                <li><img src="assets/images/social_media/footer_facebook.png" /></li>
                                <li><img src="assets/images/social_media/footer_twitter.png" /></li>
                                <li><img src="assets/images/social_media/footer_pinterest.png" /></li>
                                <li><img src="assets/images/social_media/footer_linkedin.png" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom-layer">
                    2020 {{ brandName }}® custom windows + doors. All rights reserved
                </div>
            </div>
        </section>
        -->
    </section>
</template>

<style>
html {
  font-size: unset;
}

.wtb-wrapper .step_form.two .step_form_main .group_windows_list .group_windows label:before {
  width: 1.25rem;
  height: 1.25rem;
}

.wtb-wrapper a, .wtb-wrapper a:hover {
   color: unset;
}

@media only screen and (max-width: 991px) {
  .wtb-wrapper .circle-select-option {
    background-size: 36px 38px;
  }
}

#excellenceThroughPartnership * {
  font-size: 16px;
}

#excellenceThroughPartnership h3 {
  font-size: 2rem;
}

@media (min-width: 640px) {
  #excellenceThroughPartnership * {
    font-size: 1.25rem;
  }
  #excellenceThroughPartnership h3 {
    font-size: 2rem;
  }
}

.finance_badge {
   margin: unset !important;
}

</style>

<style lang="scss" scoped>
.wtb-wrapper .use-my-location {
    position: absolute;
    width: 303px;
    height: 30px;
    color: #FFFFFF;
    text-align: center;
    top: 78px;
    text-transform: uppercase;
    padding: 3px;
    cursor: pointer;
    max-width: 213px;
}

@media(max-width: 991px) {
    .wtb-wrapper .full-width-on-mobile {
        max-width: unset;
        width: 100%;
    }
    .wtb-wrapper .use-my-location {
        top: 80px;
        height: 40px;
        padding-top: 3px;
        width: 100%;
        max-width: unset;
    }
}

.wtb-wrapper .position-relative {
    position: relative;
}

.wtb-wrapper .finance_badge {
    width: 120px;
    margin-top: 10px;
}

.wtb-wrapper .form_section {
    min-height: 0;
}

.wtb-wrapper .step_form_main {
    padding: 25px 32px 65px;
}

.wtb-wrapper .step_form_main.step_two {
    padding-top: 30px;
}

.wtb-wrapper .button_cont .custom_btn {
    width: 10rem;
}

.wtb-wrapper .dealer_weburl_approved a {
    display: block;
}

.wtb-wrapper .button_cont button {
    font-weight: 400;
    width: 100%;
    border-width: 0;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #f6b221;
    color: #003865;
    height: 57px;
    font-size: 21px;
    font-family: inherit;
}

.wtb-wrapper .button_cont button.prev_btn {
    float: left;
    font-weight: 300;
    background-color: #003865;
    color: #f6b221;
}

.wtb-wrapper .dealer_etp_status button,
.wtb-wrapper .dealer_etp_status a {
    width: 160px !important;
}

.wtb-wrapper .dealer_etp_status a {
    padding-top: 10px;
}

.wtb-wrapper .dealer-locator-results {
    float: left;
}

.wtb-wrapper .dealer-chat-online {
    margin-top: 20px;
}

.wtb-wrapper .dealer-chat-online img {
    display: inline-block;
    width: 25px;
    height: 20px;
    margin-right: 5px;
}

.wtb-wrapper .dealer-list-dealer-name {
    font-weight: 500;
}

.wtb-wrapper .dealer-list-dealer-name:hover {
    color: #3d4852;
}

@media only screen and (max-width: 991px) {
    .wtb-wrapper .finance_badge {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}

#dyoZipRedesign {
  -webkit-appearance: button !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0;
}

#dyoFromRedesign {
  -webkit-appearance: button !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.underline {
  text-decoration: underline !important;
}
</style>

<script>
import * as VueGoogleMaps from "vue2-google-maps";
let VueScrollTo = require("vue-scrollto");

import Slick from "vue-slick";
import DealerLocator from "./DealerLocator";
import SvgLocation from './SvgLocation.vue';
import SvgPhone from './SvgPhone.vue';

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GMAP_API_KEY,
        libraries: "places",
        components: "country:us"
    },
    installComponents: true
});

import GoogleMap from "./GoogleMap";
import QuoteStepFormRedesign from "./QuoteStepFormRedesign.vue";

let default_etp_background_image = 'assets/images/circle_innovators_background.jpg';
export default {
    name: "FindDealerRedesign",
    data() {
        return {
            isActive: "Diamond",
            dealers: {},
            mapSelector: "https",
            show_results: "none",
            show_distance: 1,
            search_status: 0,
            bannerImage: "assets/images/banner_new.jpg",
            circleImage: "assets/images/ETP_cursive_" + this.brand + ".png",
            brandName: "PGT",
            data_form: "shared",
            params: {
                zipcode: "",
                distance: 15,
                latitude: 0.0,
                longitude: 0.0,
                type: 1,
                showroom: false,
                primary_segment: ["R"],
                brand: this.brand,
                international: false,
                retailers: false
            },
            distances: this.distanceOptions(),
            has_errors: false,
            domain: process.env.MIX_APP_DOMAIN,
            slickOptions: {
                slidesToScroll: 1,
                vertical: true,
                infinite: false
            },
            showUseMyLocationButton: false,
            no_results: false,
            innerWidth: null,
            maxHeight: 900,
            page: 1,
            perPage: 3,
            pages: [],
        };
    },
    props: {
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount: {},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        coi_dealers: {},
        states: {},
        brand: "",
        brand_info: "",
        brand_ga: "",
        iframe_domain: "",
        interested_financing: "",
        redirect_thankyou: "",
        countries: {},
        default_country: "",
        retailers_enabled: "",
        frontend_url: "", /** For images and enpoints. Not needed unless accessing from other domain */
        brand_subdomain: "",
        append_classes: "",
        client_ip: "",
        user_agent: "",
        homes_built_per_year: "",
        etp_background_image: {
            type: String,
            default: default_etp_background_image
        },
       brand_loading_hex_color: ""
    },
    components: {
        DealerLocator,
        GoogleMap,
        QuoteStepFormRedesign,
        Slick,
        SvgLocation,
        SvgPhone,
    },
    created() {
        if (this.brand == 2) {
            this.bannerImage = "assets/images/banner_cgi_s.png";
            this.brandName = "CGI";
        }
        if (this.brand == 3) {
            this.bannerImage = "assets/images/banner_windoor.jpg";
            this.brandName = "WinDoor";
        }
        if (this.brand == 4) {
            this.bannerImage = "assets/images/banner_eze.jpg";
            this.brandName = "Eze Breeze";
        }
        if (this.brand == 6) {
            this.bannerImage = "assets/images/banner-eco.jpg";
            this.brandName = "ECO";
        }
    },
    mounted() {
        //this.initApiToken();
        //this.geoLocationRequest();
        if (
            navigator.platform.indexOf("iPhone") != -1 ||
            navigator.platform.indexOf("iPad") != -1
        ) {
            this.mapSelector = "maps";
        }
        this.initGoogleApi();

        this.innerWidth = window.innerWidth;
        window.addEventListener('resize', () => {
          this.innerWidth = window.innerWidth
        })
    },
    methods: {
        selectTab(tab) {
            this.isActive = tab;
        },
        initGoogleApi(){
            if (typeof google !== "undefined" && google.maps && google.maps.places) {
                return;
            }
            let el = document.createElement('script');
            el.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.MIX_GMAP_API_KEY + "&libraries=places&language=en";
            document.body.appendChild(el);
        },
        hideUseMyLocationButton() {
            setTimeout(() => {
                this.showUseMyLocationButton = false;
            }, 200);
        },
        useMyLocation() {
          this.getUserZipCode();
        },
        sanitizeZipCode() {
            this.params.zipcode = this.params.zipcode.replace(/\D/g, '');
        },
        submitSearch() {
           if (this.show_results === 'loading') return;
            if (this.search_status === 0) {
                if (this.params.international) {
                    this.searchDealers(1);
                } else {
                    this.fetchLatLong();
                }
            } else if (this.search_status === 1) {
                this.searchDealers(1);
            }
        },
        getUserZipCode() {
            if (!google) {
                return;
            }
            var _t = this;
            navigator.geolocation.getCurrentPosition(position => {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;
                // Auto load results for lat and long
                this.params.latitude = lat;
                this.params.longitude = lng;
                this.show_results = 'loading-auto';
                this.searchDealers(1);
                var resultsWrapperElements = this.$el.getElementsByClassName('results-wrapper');
                if (resultsWrapperElements[0]) {
                    resultsWrapperElements[0].scrollIntoView({behavior: 'smooth'});
                }

                // Nevertheless, try to load the user zipcode derived from the data we just got
                // and place it in the input, at least for the user's information
                var point = new google.maps.LatLng(lat, lng);
                new google.maps.Geocoder().geocode({
                        'latLng': point
                    },
                    function(res, status){
                        if (status === "OK") {
                            // Try to get the zipcode from the first result that allows to do so
                            for (var i = 0; i < res.length; i++) {
                                var address = res[i].formatted_address;
                                var zip = address.match(/,\s\w{2}\s(\d{5})/);
                                if (zip && zip[1]){
                                    _t.params.zipcode = zip[1];
                                    break;
                                }
                            }
                        }
                    })
            });
        },
        fetchLatLong() {
            if (!this.params.zipcode) {
                this.has_errors = true;
                return true;
            }

            this.show_results = "loading";

            window.axios.defaults.headers.common = {
                Accept: "application/json",
                "Content-Type": "application/json"
            };

            var map_url =
                "https://maps.googleapis.com/maps/api/geocode/json?key=" +
                process.env.MIX_GMAP_API_KEY +
                "&components=postal_code:" +
                this.params.zipcode;
            axios.get(map_url).then(
                response => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0) {
                            this.no_results = false;
                            var location =
                                response.data.results[0].geometry.location;
                            this.params.latitude = location.lat;
                            this.params.longitude = location.lng;
                            this.searchDealers(1);
                        } else {
                            this.no_results = true;
                            this.show_results = "none";
                            this.dealers = [];
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        searchDealers(search_type) {
          this.params.type = search_type;
          this.page = 1;

            axios.post(this.url_prepend + "api/dealer/search", this.params).then(
                response => {
                    const resultContainer = document.getElementById('result-container');
                    resultContainer.scrollIntoView({behavior: "smooth", block: "start"});

                    if (response.data.success && response.data.dealers && response.data.dealers.length) {
                      this.no_results = false;
                      this.dealers = response.data.dealers;
                      this.show_results = "yes";
                      this.show_distance = search_type == 1 ? 1 : 0;
                    } else {
                      this.no_results = true;
                      this.show_results = "none";
                      this.dealers = [];
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        distanceOptions() {
            var distance = [
                { id: 15, value: "15 Miles" },
                { id: 20, value: "20 Miles" },
                { id: 25, value: "25 Miles" },
                { id: 30, value: "30 Miles" },
                { id: 50, value: "50 Miles" },
                { id: 100, value: "100 Miles" }
            ];

            return distance;
        },
        showQuotePopup(dealer) {
            this.$modal.show(
                QuoteStepFormRedesign,
                {
                    dealer: dealer,
                    brand: this.brand,
                    brandName: this.brandName,
                    brand_ga: this.brand_ga,
                    brandInfo: this.brand_info,
                    domain: this.domain,
                    service_types: this.service_types,
                    home_types: this.home_types,
                    new_buy_plans: this.new_buy_plans,
                    window_amount: this.window_amount,
                    door_amount: this.door_amount,
                    window_importance: this.window_importance,
                    interested_products: this.interested_products,
                    lead_types: this.lead_types,
                    business_types: this.business_types,
                    project_conditions: this.project_conditions,
                    project_types: this.project_types,
                    states: this.states,
                    show_close: true,
                    interested_financing: this.interested_financing,
                    redirect_thankyou: this.redirect_thankyou,
                    countries: this.countries,
                    default_country: this.default_country,
                    data_form: 'unique',
                    frontend_url: this.frontend_url,
                    add_wrapper: true,
                    full_wrapper_classes: this.full_wrapper_classes,
                    client_ip: this.client_ip,
                    homes_built_per_year: this.homes_built_per_year,
                },
                {
                    height: "auto",
                    clickToClose: false,
                    name: "quote_modal"
                }
            );

            setTimeout(() => {
                parent.postMessage({ task: "scroll_top" }, "*");
            }, 50);
        },
        showBusinessPopup() {
            this.$modal.show(BusinessContact);
        },
        initApiToken() {
            axios
                .post(this.url_prepend + "/api/token/request", {
                    dealer_id: "general",
                    secret: "general"
                })
                .then(
                    response => {
                        if (response.data.success) {
                            console.log(response.data.message);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        },
        geoLocationRequest() {
            // var $this = this;
            // var geoSuccess = function(position) {
            //     $this.params.latitude = position.coords.latitude;
            //     $this.params.longitude = position.coords.longitude;
            //     $this.searchDealers(0);
            // };
            // var geoError = function(error) {
            //     console.log(
            //         "Error occurred. Error code: " +
            //             error.code +
            //             ", Error message: " +
            //             error.message
            //     );
            // };
            //navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        },
        scrollTo(elementId) {
          const element = document.querySelector(elementId);
          element.scrollIntoView({behavior: "smooth", block: "start"});
        },
        setPages () {
          let numberOfPages = Math.ceil(this.dealers.length / this.perPage);
          for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
          }
        },
        paginate (dealers) {
          let page = this.page;
          let perPage = this.perPage;
          let from = (page * perPage) - perPage;
          let to = (page * perPage);
          return  dealers.slice(from, to);
        },
    },
    computed: {
        url_prepend() {
            return this.frontend_url ? this.frontend_url : '';
        },
        brand_subdomain_prefix() {
            return this.brand_subdomain ? this.brand_subdomain : '';
        },
        full_wrapper_classes() {
            let res = 'wtb-wrapper';
            if (this.append_classes) {
                res += ' ' + this.append_classes;
            }
            return res;
        },
        full_etp_background_image() {
            let lc_background_image = this.etp_background_image ? this.etp_background_image.toLowerCase() : null;
            if (this.etp_background_image
                && (lc_background_image.indexOf('https://') === 0
                    || lc_background_image.indexOf('http://') === 0)
            ) {
                return this.etp_background_image;
            }
            let full = this.url_prepend ? this.url_prepend : '';
            full += this.etp_background_image ? this.etp_background_image : default_etp_background_image;
            return full;
        },
        brandPrimaryColor() {
          let color = 'ezeprimary';
          switch (this.brand) {
            case 2:
              color = 'cgi';
              break;
            case 3:
              color = 'windoorGold';
              break;
            case 4:
              color = 'ezeprimary'
              break;
            case 6:
              color = 'ecoGreen'
              break;
            default:
              color = 'pgt';
          }

          return color;
        },
        brandSecondaryColor() {
          let color = 'pgtGray';
          switch (this.brand) {
            case 2: // CGI
              color = 'cgi-dark-grey';
              break;
            case 3:
              color = 'windoor';
              break;
            case 4:
              color = 'eze'
              break;
            case 6:
              color = 'ecoDark'
              break;
            default:
              color = 'pgtGray';
          }

          return color;
        },
        brandPrimaryColorHex() {
            let color = '#326295';
            switch (this.brand) {
                case 1:
                    color= '#133A64';
                    break;
                case 2:
                    color = '#AF272F';
                    break;
                case 3:
                    color = '#B9975B';
                    break;
                case 4:
                    color = '#326295'
                    break;
                case 6:
                    color = '#6DA947'
                    break;
                default:
                    color = '#133A64';
            }

            return color;
        },
        brandETPTitleColorHex() {
            if (this.brand === 3) {
                return '#000000';
            }
            if (this.brand === 6) {
                return '#212A3C';
            }
            return this.brandPrimaryColorHex;
        },
        brandPlatinumTitleColorHex() {
            switch (this.brand) {
                case 3:
                    return '#bec1c4';
                    break;
            }
            return this.brandPrimaryColorHex;
        },
        brandGoldTitleColorHex(){
            switch (this.brand) {
                case 3:
                    return '#bec1c4';
                    break;
            }
            return this.brandPrimaryColorHex;
        },

      brandPrimaryFontFamily() {
        let fontFamily = 'campton';
        switch (this.brand) {
          case 2:
            fontFamily = 'adellesans';
            break;
          case 3:
            fontFamily = 'gibson';
            break;
          case 4:
            fontFamily = 'opensans'
            break;
          case 6:
            fontFamily = 'ptsans'
            break;
          default:
            fontFamily = 'campton';
        }

        return fontFamily;
      },

      displayedDealers() {
        return this.paginate(this.dealers);
      },

        platinum() {
            return _.filter(this.coi_dealers_hardcoded, {level: 3});
        },
        diamond() {
            return _.filter(this.coi_dealers_hardcoded, {level: 2});
        },
        gold() {
            return _.filter(this.coi_dealers_hardcoded, {level: 1});
        },
        brandBgColor() {
            if (this.brand.id === 3) {
                return '#B9975B';
            }
            return '';
        },
        coi_dealers_hardcoded() {
            return [
                // GOLD
                { name: "A BETTER VIEW OF BREVARD", level: 1 },
                { name: "A L THOMPSON", level: 1 },
                { name: "BOCA IMPACT WINDOW AND DOOR", level: 1 },
                { name: "CARROLLWOOD WINDOW & DOOR", level: 1 },
                { name: "CLEARVIEW WINDOWS & DOORS, INC", level: 1 },
                { name: "CLEARWATER WINDOW & DOOR", level: 1 },
                { name: "CROW INDUSTRIES", level: 1 },
                { name: "D'S COASTAL WINDOWS INC", level: 1 },
                { name: "DALY ALUMINUM", level: 1 },
                { name: "DOVI WINDOW DESIGNS", level: 1 },
                { name: "FLORIDA SOLAR & AIR", level: 1 },
                { name: "FORTRESS WINDOWS", level: 1 },
                { name: "GUARDIAN HURRICANE PROTECTION", level: 1 },
                { name: "HBS INC", level: 1 },
                { name: "KIMAL LUMBER COMPANY", level: 1 },
                { name: "MULLET'S ALUMINUM PRODUCTS INC", level: 1 },
                { name: "NSI WINDOWS & DOORS, INC", level: 1 },
                { name: "R L JAMES, INC", level: 1 },
                { name: "SEAVIEW BUILDING SOLUTIONS", level: 1 },
                { name: "SOUTHEASTERN SASH & DOOR", level: 1 },
                { name: "SUN WINDOW & DOOR", level: 1 },
                { name: "TITAN WINDOW AND DOOR", level: 1 },

                // DIAMOND
                {name: "ALL GLASS & WINDOWS", level: 2},
                {name: "BNT SERVICES", level: 2},
                {name: "CARIBBEAN IMPACT WINDOWS & DOORS", level: 2},
                {name: "DIAMOND GLASS & ALUMINUM", level: 2},
                {name: "DIRECT WINDOW & DOOR", level: 2},
                {name: "M&W WINDOWS", level: 2},
                {name: "NAPLES LUMBER & SUPPLY", level: 2},
                {name: "O'DONNELL IMPACT WINDOWS & STORM PROTECTION", level: 2},
                {name: "PRESTIGE WINDOWS", level: 2},
                {name: "R Q BUILDING PRODUCTS INC", level: 2},
                {name: "SARASOTA GLASS & MIRROR", level: 2},
                {name: "SCREENBUILDERS", level: 2},
                {name: "SUNCOAST CONTRACTORS SUPPLY", level: 2},
                {name: "THE GLASS PROFESSIONALS INC", level: 2},
                {name: "VERO GLASS & MIRROR", level: 2},

                // PLATINUM

                { name: "ALL AMERICAN SHUTTERS & GLASS, INC", level: 3 },
                { name: "AMAZING EZ-SCREEN PORCH WDWS", level: 3 },
                { name: "ATLANTIC WINDOWS & SHUTTERS", level: 3 },
                { name: "BAHAMA GLASS & WINDOW INC", level: 3 },
                { name: "BAYWEST GLASS & GLAZING INC", level: 3 },
                { name: "COMPLETE ALUMINUM", level: 3 },
                { name: "DOUGLAS MANUFACTURING", level: 3 },
                { name: "ENGLEWOOD GLASS & MIRROR &AUTO", level: 3 },
                { name: "FLORIDA WINDOWS & DOORS", level: 3 },
                { name: "GENERAL GLASS & WINDOW INC", level: 3 },
                { name: "JVR IMPACT WINDOWS & DOORS", level: 3 },
                { name: "KAMRELL WINDOWS & DOORS", level: 3 },
                { name: "LOUMAC DISTRIBUTORS", level: 3 },
                { name: "NAPLES GLASS & ALUMINUM INC", level: 3 },
                { name: "ROYAL PALM ALUMINUM INC", level: 3 },
                { name: "SOUTHERN EXPOSURE SUNROOMS", level: 3 },
                { name: "WEATHER TITE WINDOWS", level: 3 },
                { name: "WEATHERWALL ENCLOSURES SYSTEMS", level: 3 },
            ];
        }
    },
    watch: {
        "params.international": function(val) {
            if (val) {
                this.params.primary_segment = [];
            } else {
                this.params.primary_segment = ["R"];
            }
        },
        dealers(newValue, oldValue) {
          if (this.maxHeight < 1024) {
            this.setPages();
          }

          let vm = this;
          this.$nextTick(() => {
            if (vm.maxHeight >= 1024) {
              let nodes = document.querySelectorAll("div[data-name='desktopDealer']");
              let iterations = 0;
              let dealersToShow = 5;
              let maxHeight = 0;
              nodes.forEach(function (node) {
                if  (iterations === dealersToShow) return;
                iterations += 1;

                maxHeight += node.clientHeight;
              })

              vm.maxHeight = maxHeight;
            }
          })
        }
    }
};
</script>

<style lang="scss" scoped>

[data-forcestyle="tier-heading_1"] {
  color: #718096;
  .is-active-tier {
    color: #0a1520 !important;
  }
}

[data-forcestyle="tier-heading_2"] {
  color: #718096;
  .is-active-tier {
    color: #AF272F !important;
  }
}

[data-forcestyle="tier-heading_3"] {
  color: #718096;
  .is-active-tier {
    color: #bec1c4 !important;
  }
}

[data-forcestyle="tier-heading_4"] {
  color: #718096;
  .is-active-tier {
    color: #326295 !important;
  }
}

[data-forcestyle="tier-heading_6"] {
  color: #718096;
  .is-active-tier {
    color: #6DA947 !important;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 20px !important;
  }
}

[data-forcestyle="tier-heading_7"] {
  color: #718096;
  .is-active-tier {
    color: #006eb0 !important;
  }
}
</style>
