<template>
    <div class="contact-item actionable" @click="showPopup">
        <slot></slot>
    </div>
</template>

<script>
import QuoteStepFormRedesign from "./QuoteStepFormRedesign";
import QuoteStepForm from "./QuoteStepForm";
import EzeBreezeQuoteForm from "./EzeBreezeQuoteForm.vue";

export default {
    props: {
        dealer: {},
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        door_amount: {},
        window_importance: {},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        states: {},
        brand: "",
        brandObject: {
            type: Object
        },
        source_id: "",
        redirect_thankyou: "",
        countries: {},
        default_country: "",
        brand_ga: {},
        interested_financing: "",
        show_close: "",
        homes_built_per_year: "",
    },

    methods: {
        showPopup() {
            let component = QuoteStepFormRedesign;
            if (this.brand == 3) {
                component = QuoteStepForm;
            }
            if (this.brand == 4) {
                component = EzeBreezeQuoteForm;
            }
            this.$modal.show(
                component,
                {
                    dealer: this.dealer,
                    brand: this.brand,
                    brandName: this.brandName,
                    brand_ga: this.brand_ga,
                    brandObject: this.brandObject,
                    brand_info: this.brandObject,
                    domain: this.domain,
                    service_types: this.service_types,
                    home_types: this.home_types,
                    new_buy_plans: this.new_buy_plans,
                    window_amount: this.window_amount,
                    door_amount: this.door_amount,
                    window_importance: this.window_importance,
                    interested_products: this.interested_products,
                    lead_types: this.lead_types,
                    business_types: this.business_types,
                    project_conditions: this.project_conditions,
                    project_types: this.project_types,
                    states: this.states,
                    show_close: true,
                    interested_financing: this.interested_financing,
                    redirect_thankyou: this.redirect_thankyou,
                    countries: this.countries,
                    default_country: this.default_country,
                    shownOnModal: true,
                    data_form: "unique",
                    full_wrapper_classes: 'wtb-wrapper'
                },
                {
                    height: "auto",
                    adaptive: true,
                    clickToClose: false,
                    name: "quote_modal",
                    scrollable: true
                },
                {
                    'before-open': this.beforeOpen,
                    'before-close': this.beforeClose
                })
        },

        beforeOpen (event) {
            document.querySelector("body").classList.add('overflow-hidden');
        },
        beforeClose (event) {
           document.querySelector("body").classList.remove('overflow-hidden');
        }
    },
    computed: {
        brandName: function(){
            if (this.brand.id === 1) return 'PGT';
            if (this.brand.id === 2) return 'CGI';
            if (this.brand.id === 3) return 'WD';
            if (this.brand.id === 4) return 'EZE';
            if (this.brand.id === 6) return 'Anlin';
            if (this.brand.id === 6) return 'WWS';
        }
    }
}
</script>
