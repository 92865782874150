<template>
    <div class="question-slide">
        <div class="slider-container">
            <ul class="slider">
                <li
                    class="slide bg-no-repeat bg-cover bg-center py-20"
                    :style="{
                        'background-image': 'url(/images/banner1.png)'
                    }"
                    data-aos="fade-in"
                    data-aos-once="true"
                >
                    <div
                        class="fields-wrap container mx-auto px-6 text-center"
                    >
                        <h4 data-aos="zoom-in" data-aos-once="true">
                            {{dealer.name}}
                        </h4>
                        <div
                            class="uppercase text-center text-lg font-hairline tracking-widest"
                            data-aos="fade-up"
                            data-aos-easing="ease"
                            data-aos-delay="400"
                            data-aos-once="true"
                        >
                        </div>
                        <QuoteStepForm
                            :dealer="this.dealer"
                            :service_types="this.service_types"
                            :home_types="this.home_types"
                            :new_buy_plans="this.new_buy_plans"
                            :window_amount="this.window_amount"
                            :door_amount="this.door_amount"
                            :window_importance="this.window_importance"
                            :interested_products="this.interested_products"
                            :lead_types="this.lead_types"
                            :business_types="this.business_types"
                            :project_conditions="this.project_conditions"
                            :project_types="this.project_types"
                            :states="this.states"
                            :interested_financing="this.interested_financing"
                            >
                        </QuoteStepForm>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import PopupGetQuote from "./PopupGetQuote.vue";
import QuoteStepForm from "./QuoteStepForm.vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GMAP_API_KEY,
        libraries: "places",
        components: "country:us"
    },
    installComponents: true
});

export default {
    name: "QuestionSlide",
    props: {
        dealer: {},
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount:{},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        states: {}
    },
    components: {
        PopupGetQuote,
        QuoteStepForm
    },
    data() {
        return {
            fields: [
                {
                    id: 0,
                    heading: this.dealer.name,
                    label: "Question",
                    title: "What Type Of Project?",
                    backgroundUrl: require("./../../images/banner1.png"),
                    options:
                        `<button class='btn flex-1 bg-white hover:bg-slecondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase' ref='contact_button'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon1.png") +
                        `" />Replacement<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon2.png") +
                        `" />New<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon3.png") +
                        `" />Repair</button>`
                },
                {
                    id: 1,
                    heading: "Dealer SubDomain 2",
                    label: "Question #2",
                    title: "What Type Of Project?",
                    backgroundUrl: require("./../../images/banner1.png"),
                    options:
                        `<button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon1.png") +
                        `" />Replacement<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon2.png") +
                        `" />New<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon3.png") +
                        `" />Repair</button>`
                },
                {
                    id: 2,
                    heading: "Dealer SubDomain 3",
                    label: "Question #3",
                    title: "What Type Of Project?",
                    backgroundUrl: require("./../../images/banner1.png"),
                    options:
                        `<button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon1.png") +
                        `" />Replacement<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon2.png") +
                        `" />New<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon3.png") +
                        `" />Repair</button>`
                },
                {
                    id: 3,
                    heading: "Dealer SubDomain 4",
                    label: "Question #4",
                    title: "What Type Of Project?",
                    backgroundUrl: require("./../../images/banner1.png"),
                    options:
                        `<button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon1.png") +
                        `" />Replacement<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon2.png") +
                        `" />New<br/>Windows/Doors</button>
                    <button class='btn flex-1 bg-white hover:bg-secondary text-primary rounded m-6 p-4 text-xs font-semibold uppercase'><img class="block mx-auto w-16 mb-2" src="` +
                        require("./../../images/icon3.png") +
                        `" />Repair</button>`
                }
            ],
            activeSlide: 0,
            isActive: 0,
            isMounted: false
        };
    },
    computed: {
        styleObject: function() {
            if (!this.isMounted) return;

            const width = 0 - this.activeSlide * this.$refs.slider.clientWidth;

            return {
                transform: "translateX(" + width + "px)"
            };
        }
    },
    methods: {
        changeSlide: function(num) {
            this.activeSlide = num;
        },
        nextSlide: function() {
            if (this.activeSlide < this.$refs.slider.childNodes.length - 1) {
                this.activeSlide++;
                this.isActive = this.activeSlide;
            }
        },
        prevSlide: function() {
            if (this.activeSlide > 0) {
                this.activeSlide--;
                this.isActive = this.activeSlide;
            }
        },
        scrollToCTA() {
            console.log("here");
        },
        showPopup() {
            this.$modal.show(PopupGetQuote, { dealer: this.dealer });
        }
    },
    mounted() {
        this.isMounted = true;
    }
};
</script>
