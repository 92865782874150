'use strict';
import ChatBot from "../ChatBot";
import ChatBotBehavior from "../ChatBotBehavior";

export default function(name, ChatBotInstance) {
    if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
    name = typeof name !== "undefined" && name ? name : "captures_intent";
    this.ChatBot  = ChatBotInstance;
    this.Behavior = new ChatBotBehavior(name);
    let $this = this;

    let possible_values = [];
    /*
    CU-2k56qvg
    if (this.ChatBot.parentChat.available_chat_features.indexOf('Virtual consultation') > -1) {
        possible_values.push('Schedule a virtual consultation');
    }
    */
    if (this.ChatBot.parentChat.available_chat_features.indexOf('In-home visit') > -1) {
        possible_values.push('Schedule an in-home visit');
    }
    if (this.ChatBot.parentChat.available_chat_features.indexOf('Showroom visit') > -1) {
        possible_values.push('Schedule a showroom visit');
    }

    if (this.ChatBot.parentChat.inOnlineFlow) {
        possible_values = possible_values.concat('Speak to someone');
    } else {
        possible_values = possible_values.concat('Send a message');
    }

    this.Behavior
        .whenEvents('chatOpened', 'startOver')
        .when(() => {
            return $this.ChatBot.parentChat.messages.length === 0 || $this.ChatBot.getLastUsedInputPointer().step_name === 'start_again'
        })
        .template('👋 Welcome! What brings you here today?<br />' +
            '<div class="text-center">' +
            possible_values.reduce((t,i) => {
                return t + '<button class="input-button" data-input-name="'+name+'" data-input-value="'+i+'">'+ i +'</button>'
            }, '') +
            '</div>'
         )
        .captureInput()
        .disableStandardInput();

    this.make = function() {
        return this.Behavior;
    }
}
