<template>
  <div class="section-2 bg-white relative z-10">
    <div class="container mx-auto py-32 px-6">
      <div class="lg:flex lg:flex-row-reverse">
        <div class="flex-1 max-w-lg mx-auto mb-10 lg:m-0">
          <div class="yellow-tr-pattern">
            <div class="pattern" data-aos="fade-left" data-aos-delay="200"></div>
            <img src="./../../images/img3.png" />
          </div>
        </div>
        <div class="flex-1 lg:pr-12">
            <div class="text-tertiary leading-loose text-sm lg:max-w-lg lg:float-right type-text">
              <h2 class="text-primary">Energy Efficient<br/>Windows and Doors:</h2>

              <p>The glass you choose for your windows and doors can dramatically
              increase the energy efficiency of your home. We are proud to offer
              insulating glass, laminated glass and laminated insulating glass options
              that all contribute to decreased indoor energy usage, as well
              as lower heating and cooling costs.</p>

              <p>We've built in multiple opportunities to achieve ENERGY STAR&reg; ratings
              for engery performance so you can save even more on energy costs. These options include:
                <ul>
                  <li>HIGH-PERFORMANCE LOW-E to deflect solar heat gain and
                  keep unwanted heat outside your home.</li>
                  <li>GLASS TINTS which reduce heat transmitted through your windows.</li>
                  <li>ARGON GAS which helps reflect outside heat to regulate the temperatures inside your home.</li>
                </ul>
              </p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Section2'
}
</script>
