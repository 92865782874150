'use strict';
import ChatBot from "../ChatBot";
import ChatBotBehavior from "../ChatBotBehavior";

export default function(name, ChatBotInstance) {
    if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
    name = typeof name !== "undefined" && name ? name : "start_again";
    this.ChatBot  = ChatBotInstance;
    this.Behavior = new ChatBotBehavior(name);
    let $this = this;

    let possible_values = [
        'Yes', 'No'
    ];

    this.Behavior
        .setRenderer(this.ChatBot.renderer)
        .captureInput()
        .disableStandardInput()
        .whenEvents('promptFurtherAssistance')
        .template('Is there anything else I can help you with?<br />' +
            '<button class="input-button" data-input-name="start_again" data-input-value="Yes">Yes</button>' +
            '<button class="input-button" data-input-name="start_again" data-input-value="No">No</button>')
        .afterInput(input => {
            if (input === 'Yes') {
                $this.ChatBot.setBehaviors($this.ChatBot.defaultBehaviors);
                $this.ChatBot.emit('startOver');
            } else {
                $this.ChatBot.setBehaviors([
                    new ChatBotBehavior('salute')
                        .setRenderer($this.ChatBot.renderer)
                        .captureInput()
                        .whenEvents('endedByCustomer')
                        .template('Thank you for your time and have a great day!')
                ]);
                $this.ChatBot.emit('endedByCustomer')
            }
        });

    this.make = function() {
        return this.Behavior;
    }
}
