import ChatBot from "../ChatBot";
import ChatBotBehavior from "../ChatBotBehavior";
import PhoneNumberValidator from "../Validators/PhoneNumber";
import EmailValidator from "../Validators/EmailAddress";

export default class {
    ChatBot = null;
    constructor(ChatBotInstance) {
        if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
        this.ChatBot = ChatBotInstance;
    }

    getBehaviorList() {
        let $ChatBot = this.ChatBot;
        return [
            new ChatBotBehavior('captures_name').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('flowStarted')
                .template('Have a question? For us to best serve you, please share your first and last name.')
                .captureInput(),
            new ChatBotBehavior('captures_email_address').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_name';
                })
                .template('Thanks, {val:captures_name}. What’s your email address?')
                .setValidator(EmailValidator)
                .captureInput()
                .after(function() {
                    let name = this.getInput('captures_name');
                    if (name) {
                        this.parentChat.customer_name = name;
                    }
                }),
            new ChatBotBehavior('captures_allow_contact').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_email_address';
                })
                .template('Thank you for sharing that info.<br/>' +
                    'We use email to learn more about your experience and provide you with the latest information and materials for {binded:brand_name} products. Would you like us to contact you with updates using this email?' +
                    '<br /><button class="input-button" data-input-name="allow_contact" data-input-value="Yes">Yes</button><button class="input-button" data-input-name="allow_contact" data-input-value="No">No</button>'
                )
                .captureInput()
                .disableStandardInput(),
            new ChatBotBehavior('captures_reason').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_allow_contact'
                })
                .template("Understood. What can we do for you?")
                .captureInput(),
            new ChatBotBehavior('captures_allow_phone').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_reason'
                })
                .template("Would you like a representative from {binded:dealer_name} to give you a call?" +
                    '<br /><button class="input-button" data-input-name="allow_phone" data-input-value="Yes">Yes</button><button class="input-button" data-input-name="allow_phone" data-input-value="No">No</button>')
                .captureInput()
                .disableStandardInput(),
            // BEGIN CUSTOMER ALLOWS PHONE CONTACT
            new ChatBotBehavior('captures_phone').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_allow_phone' && $ChatBot.getInput('captures_allow_phone').toLowerCase() === 'yes'
                })
                .template("Absolutely. Please give us a good number you can be reached at.")
                .setValidator(PhoneNumberValidator)
                .setMaxLengthTo(10)
                .captureInput(),
            new ChatBotBehavior('salute_with_phone_contact').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_phone' && $ChatBot.getInput('captures_allow_phone').toLowerCase() === 'yes'
                })
                .template("Thank you for sending {binded:dealer_name} a message, a representative will be in touch shortly.")
                .after(() => { helperInitOfflineChat($ChatBot); })
                .captureInput(),
            // END CUSTOMER ALLOWS PHONE CONTACT
            // BEGIN CUSTOMER DOES NOT ALLOW PHONE CONTACT

            new ChatBotBehavior('salute_no_phone_contact').once()
                .setRenderer(this.ChatBot.renderer)
                .whenEvents('message')
                .when(function(){
                    return $ChatBot.getLastUsedInputPointer().step_name === 'captures_allow_phone' && $ChatBot.getInput('captures_allow_phone').toLowerCase() === 'no'
                })
                .template("Not a problem. Thank you for sending {binded:dealer_name} a message, a representative will be in touch shortly.")
                .after(() => { helperInitOfflineChat($ChatBot); })
                .captureInput(),
            // END CUSTOMER DOES NOT ALLOW PHONE CONTACT
        ];
    }
}



var helperInitOfflineChat = function(ChatBotInstance) {
    let parentChat = ChatBotInstance.parentChat;
    parentChat.customer_name = ChatBotInstance.getInput('captures_name');
    parentChat.customer_email = ChatBotInstance.getInput('captures_email_address');
    parentChat.customer_phone = ChatBotInstance.getInput('captures_phone');
    parentChat.allow_email_contact = ChatBotInstance.getInput('captures_allow_contact');
    parentChat.allow_phone_contact = ChatBotInstance.getInput('captures_allow_phone');
    parentChat.storeOfflineChatAndEndSession();
}
