'use strict';
import ChatBotBehavior from "../ChatBotBehavior";
import ChatBot from "../ChatBot";
import TextChat from "../ChatFlows/TextChat";
import InHomeVisitChat from "../ChatFlows/InHomeVisitChat";
import VirtualConsultationChat from "../ChatFlows/VirtualConsultationChat";
import ShowroomVisitChat from "../ChatFlows/ShowroomVisitChat";

export default class {
    ChatBot;

    constructor(ChatBotInstance) {
        if (!(ChatBotInstance instanceof ChatBot)) throw new Error();
        this.ChatBot = ChatBotInstance;
    }

    process() {
        let $this = this;
        return new ChatBotBehavior('process_intent')
            .whenEvents('message')
            .when(() => {
                return $this.ChatBot.getLastUsedInputPointer().step_name === 'captures_intent';
            })
            .disableStandardOutput()
            .after(() => {
                let flow = this.matchFlow($this.ChatBot.getInput('captures_intent'));
                $this.ChatBot.behaviors = flow.getBehaviorList();
                $this.ChatBot.emit("flowStarted");
            });
    }

    matchFlow(input) {
        input = input.toLowerCase();
        switch (input) {
            case 'schedule an in-home visit':
                return new InHomeVisitChat(this.ChatBot);
                break;
            case 'schedule a virtual consultation':
                return new VirtualConsultationChat(this.ChatBot);
            case 'schedule a showroom visit':
                return new ShowroomVisitChat(this.ChatBot);
                break;
            case 'send a message':
            case 'speak to someone':
                return new TextChat(this.ChatBot);
            default:
                throw new Error("No matching Flow");
        }
    }
}
