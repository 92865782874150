import axios from "axios";

export default class {
    firstname   = null;
    lastname    = null;
    address1    = null;
    city        = null;
    state       = null;
    zip         = null;
    country     = null;
    dealer_id   = null;
    email       = null;
    phone       = null;
    information = {};
    preferred_contact_method = null;
    newsletter  = null;
    brand_id    = null
    source_id   = null;
    interested_financing = 0;
    endpoint    = '/api/lead';
    lead_type   = 'H';
    chat_id     = null;
    is_appointment = null;
    need_installation = null;
    interested_products_selected = [];
    patio_cover = null;


    constructor(dealer_id, brand_id) {
        if (!dealer_id) { throw new Error("dealer_id is required for a chat lead") }
        if (!brand_id)  { throw new Error("brand_id is required for a chat lead") }
        this.dealer_id = dealer_id;
        this.brand_id  = brand_id;
    }

    setEndpoint(endpoint) {
        this.endpoint = endpoint;
    }

    setFirstname(firstname) {
        this.firstname = firstname;
    }
    setLastname(lastname) {
        this.lastname = lastname;
    }
    setAddress(address) {
        this.address1 = address;
    }
    setCity(city) {
        this.city = city;
    }
    setState(state) {
        this.state = state;
    }
    setZip(zip) {
        this.zip = zip;
    }
    setCountry(country) {
        this.country = country;
    }
    setEmail(email) {
        this.email = email;
    }
    setPhone(phone) {
        this.phone = phone;
    }
    setInformation(key, value) {
        this.information[key] = value;
    }
    setPreferredContactMethod(value) {
        this.preferred_contact_method = value;
    }
    setNewsletter(value) {
        this.newsletter = value;
    }
    setChatId(id) {
        this.chat_id = id;
    }
    setInstallation(val) {
        this.need_installation = val;
    }
    setInterestedProductsSelected(valArray) {
        this.interested_products_selected = valArray;
    }
    setPatioCover(val) {
        this.patio_cover = val;
    }

    post(successCb, failureCb) {
        if (!this.endpoint) throw new Error("endpoint must be set");
        let prefered_dates = this.information['preferred_dates'] ? this.information['preferred_dates'] : null;
        let window_amount = this.information['window_amount'] ? this.information['window_amount'] : null;
        let door_amount = this.information['door_amount'] ? this.information['door_amount'] : null;
        let appointment_type = this.information['appointment_type'] ? this.information['appointment_type'] : null;
        let additional = this.information['additional'] ? this.information['additional'] : null;
        let is_appointment = this.information['is_appointment'] ? this.information['is_appointment'] : null;
        axios.post(this.endpoint, {
            leadType: this.lead_type,
            firstName: this.firstname,
            lastName: this.lastname,
            address1: this.address1,
            city: this.city,
            state: this.state,
            country: this.country,
            zip: this.zip,
            dealer_id: this.dealer_id,
            brand: this.brand_id,
            email: this.email,
            phone: this.phone,
            chat_id: this.chat_id,
            preferredAppointmentDates: prefered_dates,
            windowAmount: window_amount,
            doorAmount: door_amount,
            appointmentType: appointment_type,
            additional: additional,
            isAppointment: is_appointment,
            interested_installation: this.need_installation,
            interestedProducts: this.interested_products_selected,
            patio_cover: this.patio_cover
        })
        .then(r => {})
        .catch(e => {
            throw e;
        });
    }
}
