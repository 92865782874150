'use strict';
export default function(name) {
    let maxTimes = null;
    let callbackTrigger = null;
    let eventsTrigger = null;
    let chainable = false;
    let template;
    let renderer = null;

    this.name = name;
    this.capturesInput = false;
    this.maxInputLength = -1;
    this.disablesStdIn = false;
    this.stdOutDisabled = false;
    this.afterCallback = null;
    this.afterInputCallback = null;
    this.validator = function(){return true};
    this.validatorFailedMessage = 'Oops! Wrong input, please try again';
    this.loopUntilCallback = null;

    this.once = function() {
        maxTimes = 1;
        return this;
    }

    this.whenEvents = function(eventNames) {
        if (eventNames) {
            eventsTrigger = [].slice.call(arguments); // convert to Array
            return this;
        } else {
            return eventsTrigger;
        }
    }

    this.when = function(cb) {
        callbackTrigger = cb;
        return this;
    }

    this.chainable = function(){
        chainable = true;
        return this;
    }

    this.template = function(arg) {
        if (arg) {
            template = arg;
            return this;
        } else {
            return template;
        }
    }

    this.dispatch = function() {
        let shouldDispatch = true;
        let runtimeTemplate = typeof template === 'function' ? template() : template;
        if (typeof callbackTrigger === 'function' && callbackTrigger() !== true) {
            shouldDispatch = false;
        }
        if (shouldDispatch) {
            if (typeof maxTimes === 'number') {
                if (maxTimes > 0) {
                    maxTimes--;
                } else {
                    shouldDispatch = false;
                }
            }
        }
        if (shouldDispatch) {
            if (typeof renderer === 'function') {
                return renderer(runtimeTemplate);
            }
            return runtimeTemplate;
        }
    }

    this.captureInput = function() {
        this.capturesInput = true;
        return this;
    }

    this.disableStandardInput = function() {
        this.disablesStdIn = true;
        return this;
    }

    this.setMaxLengthTo = function(max_length) {
        this.maxInputLength = max_length;
        return this;
    }

    this.disableStandardOutput = function() {
        this.stdOutDisabled = true;
        return this;
    }

    this.setRenderer = function(callback) {
        renderer = callback;
        return this;
    }

    this.after = function(callback) {
        this.afterCallback = callback;
        return this;
    }

    this.afterInput = function(callback) {
        this.afterInputCallback = callback;
        return this;
    }

    this.setValidator = function(callback){
        this.validator = callback;
        return this;
    }

    this.loopUntil = function(callback) {
        this.loopUntilCallback = callback;
        eventsTrigger.push(`loop:${this.name}`);
        return this;
    }
}
