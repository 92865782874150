<template>
  <div class="section-3 relative z-10">
    <div class="yellow-top-styling-line"></div>
    <div class="container mx-auto py-32 px-6">
      <div class="flex justify-center">
          <a href="/"><img src="./../../images/img4.png" /></a>
          <a href="/"><img src="./../../images/img5.png" /></a>
          <a href="/"><img src="./../../images/img6.png" /></a>
      </div>
    </div>
    <!-- You can replace the img tag below with video embed code -->
    <a href="/"><img class="w-full" src="./../../images/img7.png" /></a>
  </div>
</template>

<script>

export default {
  name: 'Section3'
}
</script>
