import ChatBotBehavior from "../ChatBotBehavior";

export default function() {
    let options = [];
    let $this = this;
    if ([4].indexOf(this.ChatBot.parentChat.getBrand().id) > -1) {
        let maxPorchOptions = this.ChatBot.getBinding('patio_porch').length;
        let terminate_string = 'Done';
        options = [
            new ChatBotBehavior('captures_patio_porch_step')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('addressConfirmed', 'patioPorchQuestionInProgress')
                .template(() => {
                    let dynamicOptions = [].concat(this.ChatBot.getBinding('patio_porch'));
                    let patio_porch_wording = 'Before you select your date/times, please tell us how would you like to enclose your patio/porch. <br />';
                    if (this.ChatBot.getInput('patio_porch_selected', true) && this.ChatBot.getInput('patio_porch_selected', true).length) {
                        patio_porch_wording = this.ChatBot.getInput('patio_porch_selected', true).join(', ') + ". Anything else? <br />";
                        dynamicOptions.push({
                            name: terminate_string
                        });
                    }
                    return patio_porch_wording +
                        dynamicOptions.filter(i => {
                            return !this.ChatBot.getInput('patio_porch_selected', true) || this.ChatBot.getInput('patio_porch_selected', true).indexOf(i.name) < 0
                        }).reduce((accum, item) => {
                            return accum + '<button class="input-button" data-input-name="window_amount" data-input-value="'+ item.name +'">'+ item.name +'</button>'
                        }, '');
                })
                .afterInput((input) => {
                    //debugger;
                    let curVal = $this.ChatBot.getInput('patio_porch_selected', true);
                    if (!curVal || !curVal.length) {
                        curVal = [];
                    }
                    if (input !== terminate_string) curVal.push(input);
                    $this.ChatBot.setInput('patio_porch_selected', curVal);
                    //console.log(curVal);
                    let done = input === terminate_string || ($this.ChatBot.getInput('patio_porch_selected', true) && $this.ChatBot.getInput('patio_porch_selected', true).length >= maxPorchOptions);
                    if (done) {
                        $this.ChatBot.emit('patioPorchQuestionCompleted');
                        return true;
                    } else {
                        $this.ChatBot.emit('patioPorchQuestionInProgress');
                        return true;
                    }
                })
            ,
            new ChatBotBehavior('captures_patio_covered')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('patioPorchQuestionCompleted')
                .template('How is the patio covered? <br />'  +
                    this.ChatBot.getBinding('patio_covered').reduce((accum, item) => {
                        return accum + '<button class="input-button" data-input-name="window_amount" data-input-value="'+ item.name +'">'+ item.name +'</button>'
                    }, '')
                ),
            new ChatBotBehavior('captures_need_installation')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('message')
                .when(() => {
                    return this.ChatBot.getLastUsedInputPointer().step_name === 'captures_patio_covered';
                })
                .template('Do you need installation services? <br />'  +
                    this.ChatBot.getBinding('need_installation').reduce((accum, item) => {
                        return accum + '<button class="input-button" data-input-name="window_amount" data-input-value="'+ item.name +'">'+ item.name +'</button>'
                    }, '')
                )
                .afterInput(() => {
                    $this.ChatBot.emit('brandQuestionsCompleted');
                }),
        ];
    } else {
        options = [
            new ChatBotBehavior('captures_window_amount')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('addressConfirmed')
                .template('Before you select your date/times, please tell us how many windows you expect to need for this project. <br />' +
                    this.ChatBot.getBinding('window_amount').reduce((accum, item) => {
                        return accum + '<button class="input-button" data-input-name="window_amount" data-input-value="'+ item.name +'">'+ item.name +'</button>'
                    }, '')
                ),
            new ChatBotBehavior('captures_door_amount')
                .setRenderer(this.ChatBot.renderer)
                .captureInput()
                .disableStandardInput()
                .whenEvents('message')
                .when(() => {
                    return this.ChatBot.getLastUsedInputPointer().step_name === 'captures_window_amount';
                })
                .template('Perfect, and how many doors? <br />'  +
                    this.ChatBot.getBinding('door_amount').reduce((accum, item) => {
                        return accum + '<button class="input-button" data-input-name="window_amount" data-input-value="'+ item.name +'">'+ item.name +'</button>'
                    }, '')
                )
                .afterInput(() => {
                    $this.ChatBot.emit('brandQuestionsCompleted');
                })
        ];
    }
    return options;
};
