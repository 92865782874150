<template>
    <section>
        <plugin-preloader :brand_loading_hex_color="brand_loading_hex_color"></plugin-preloader>
        <!-- Banner Section -->
        <section class="home-banner">
            <img
                class="banner-image object-fit_cover"
                src="assets/images/banner1.png"
            />
            <div class="banner-text">Get a free quote</div>
        </section>
        <!-- /Banner Section -->
        <section class="section-content">
            <div class="clearfix">
                <div class="region-content">
                    <div class="region-content-inner">
                        <div class="search_steps">
                            <!-- <h1 class="text-center">Get a Free Quote</h1> -->
                            <p class="header_text text-center">
                                Elevating your home or property with
                                award-winning PGT® products is just a few clicks
                                away. Please answer the questions below to get
                                started.
                            </p>
                            <QuoteStepForm
                                :service_types="this.service_types"
                                :home_types="this.home_types"
                                :new_buy_plans="this.new_buy_plans"
                                :window_amount="this.window_amount"
                                :door_amount="this.door_amount"
                                :window_importance="this.window_importance"
                                :interested_products="this.interested_products"
                                :lead_types="this.lead_types"
                                :business_types="this.business_types"
                                :project_conditions="this.project_conditions"
                                :project_types="this.project_types"
                                :states="this.states"
                                :homes_built_per_year="this.homes_built_per_year"
                            >
                            </QuoteStepForm>
                        </div>
                        <div class="search-header">
                            <div class="search-or">
                                Prefer to Do Your Own Research?
                            </div>
                            <p class="search-intro-text">
                                We sell exclusively through a dealer network who
                                can help you find the right products for your
                                home or property. To find an authorized dealer
                                in your area, please enter your zip code below.
                            </p>
                        </div>
                        <div class="search-wrapper clearfix">
                            <input type="hidden" v-model="params.latitude" />
                            <input type="hidden" v-model="params.longitude" />
                            <div class="search-fields">
                                <div v-if="!params.international">
                                    <ul class="zipcode-wrapper">
                                        <li>
                                            <label class="zip-label" for=""
                                                >Zipcode</label
                                            >
                                            <div
                                                class="form-item form-type-textfield zip-input"
                                            >
                                                <input
                                                    class="form-text"
                                                    type="text"
                                                    id=""
                                                    v-model="params.zipcode"
                                                    value=""
                                                    size="10"
                                                    maxlength="10"
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <span
                                                class="text-from"
                                                v-if="show_distance === 1"
                                                >from</span
                                            >
                                            <div
                                                class="form-item form-type-select"
                                                v-if="show_distance === 1"
                                            >
                                                <select
                                                    v-model="params.distance"
                                                    class="form-select"
                                                >
                                                    <option
                                                        v-for="(item,
                                                        key) in this.distances"
                                                        :key="key"
                                                        :value="item.id"
                                                        >{{
                                                            item.value
                                                        }}</option
                                                    >
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="search-field-wrapper clearfix">
                                    <p class="mb-0 mt-2">
                                        Find The Right Match
                                    </p>
                                    <div class="form-item">
                                        <!-- <div class="form-item form-type-textfield">
                                            <input
                                                class="form-text"
                                                type="text"
                                                id=""
                                                v-model="params.zipcode"
                                                value=""
                                                size="10"
                                                maxlength="10"
                                            />
                                        </div> -->
                                        <label
                                            class="form-item form-type-checkbox"
                                        >
                                            <input
                                                class="form-checkbox"
                                                type="checkbox"
                                                v-model="params.showroom"
                                            />
                                            <span
                                                class="form-checkbox-label"
                                            ></span>
                                            Dealers with Showrooms
                                        </label>

                                        <label
                                            class="form-item form-type-checkbox"
                                        >
                                            <input
                                                class="form-checkbox"
                                                type="checkbox"
                                                value="R"
                                                v-model="params.primary_segment"
                                            />
                                            <span
                                                class="form-checkbox-label"
                                            ></span>
                                            Dealers for homeowners (I’m
                                            remodeling/upgrading)
                                        </label>

                                        <!-- <label class="form-item form-type-checkbox">
                                            <input
                                                class="form-checkbox"
                                                type="checkbox"
                                                id=""
                                                value=""
                                            />
                                            <span class="form-checkbox-label"></span>
                                            Dealers for New Home Construction/
                                             Under Construction
                                        </label> -->

                                        <label
                                            class="form-item form-type-checkbox"
                                        >
                                            <input
                                                class="form-checkbox"
                                                type="checkbox"
                                                value="B"
                                                v-model="params.primary_segment"
                                            />
                                            <span
                                                class="form-checkbox-label"
                                            ></span>
                                            Dealers for Trade/Industry
                                            Professionals (Architect, Builder,
                                            GC, New Construction, Etc)
                                        </label>

	                                    <label
			                                    class="form-item form-type-checkbox"
	                                    >
		                                    <input
				                                    class="form-checkbox"
				                                    type="checkbox"
				                                    v-model="params.international"
		                                    />
		                                    <span
				                                    class="form-checkbox-label"
		                                    ></span>
		                                    Dealers for International/Export
	                                    </label>
                                    </div>
                                </div>
                            </div>

                            <div class="search-fields mt-5">
                                <label>&nbsp;</label>
                                <input
                                    class="form-submit common-btn"
                                    type="submit"
                                    value="Submit"
                                    @click="submitSearch()"
                                />
                            </div>
                        </div>
                        <div
                            class="results-wrapper clearfix"
                            v-if="show_results == 'loading'"
                        >
                            <p>Please wait! Fetching your results.</p>
                        </div>
                        <div
                            class="results-wrapper clearfix"
                            v-else-if="show_results == 'yes'"
                        >
                            <div
                                class="result-wrapper-container"
                                v-if="dealers.length > 0"
                            >
                                <div class="dealer-locator-map">
                                    <div class="attachment attachment-before">
                                        <google-map
                                            :dealers="dealers"
                                        ></google-map>
                                    </div>
                                </div>
                                <div class="dealer-locator-results">
                                    <slick ref="slick" :options="slickOptions">
                                        <div
                                            class="results-row clearfix"
                                            v-for="dealer in dealers"
                                        >
                                            <div class="results-left">
                                                <div
                                                    class="field-title uppercase"
                                                >
                                                    {{ dealer.name }}
                                                </div>
                                                <div
                                                    class="field_geofield_distance"
                                                >
                                                    Distance:
                                                    {{
                                                        dealer.distance.toFixed(
                                                            2
                                                        )
                                                    }}
                                                    miles
                                                </div>
                                                <div
                                                    class="views-field-field_dealer_address"
                                                >
                                                    <div
                                                        class="dealer-locator-address"
                                                    >
                                                        <div
                                                            class="dealer-locator-address1"
                                                        >
                                                            {{
                                                                dealer.address1
                                                            }}
                                                        </div>
                                                        <div
                                                            class="dealer-locator-address2"
                                                        >
                                                            {{
                                                                dealer.address2
                                                            }}
                                                        </div>
                                                        <div
                                                            class="dealer-locator-city"
                                                        >
                                                            {{ dealer.city }},
                                                            {{ dealer.state }}
                                                            {{ dealer.zip }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dealer_telephone">
                                                    {{
                                                        dealer.call_rail_number
                                                            ? dealer.call_rail_number
                                                            : dealer.phone
                                                    }}
                                                </div>
                                                <div
                                                    class="dealer_weburl_approved"
                                                >
                                                    <a
                                                        :href="
                                                            'https://' +
                                                                dealer.subdomain +
                                                                '.' +
                                                                domain
                                                        "
                                                        target="_blank"
                                                        >Details</a
                                                    >
                                                </div>
                                            </div>
                                            <div class="results-right">
                                                <!-- <div class="dealer_etp_status">
                                                    <button
                                                        class="text-sm button common-holo-btn"
                                                    >
                                                        Call Now
                                                    </button>
                                                    <br />
                                                    <button
                                                        class="text-sm button common-holo-btn"
                                                        @click="
                                                            showQuotePopup(
                                                                dealer
                                                            )
                                                        "
                                                    >
                                                        More Info
                                                    </button>
                                                </div> -->
                                            </div>
                                            <div class="location-border"></div>
                                        </div>
                                    </slick>
                                </div>
                            </div>
                            <div v-else>
                                <h5>
                                    Sorry! no results to show. Please try again.
                                </h5>
                            </div>
                        </div>
                        <div class="results-wrapper clearfix" v-else></div>
                    </div>
                </div>
            </div>
        </section>
        <section class="circle-wrapper">
            <div class="section-circle">
                <div class="circle-table-cell circle-title-left">
                    <h3>Circle of Innovators</h3>
                </div>
                <div class="circle-table-cell circle-description-left">
                    Every year, the Circle of Innovators culminates with the
                    prestigious opportunity to become an official Gold,
                    Platinum, or Diamond dealer, as well as joining our team on
                    an incredible trip to celebrate success. Thank you for your
                    dedicated partnership with the PGT Innovations family of
                    brands.
                </div>
            </div>
            <div class="grid-custom">
                <div class="grid-custom-grid3">
                    <div class="form-type-select">
                        <select class="form-select circle-select-option">
                            <option>Platinum Dealers List</option>
                            <option v-for="dealer in platinum">
                                {{ dealer.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="grid-custom-grid3">
                    <div class="form-type-select">
                        <select class="form-select circle-select-option">
                            <option>Diamond Dealers List</option>
                            <option v-for="dealer in diamond">
                                {{ dealer.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="grid-custom-grid3">
                    <div class="form-type-select">
                        <select class="form-select circle-select-option">
                            <option>Gold Dealers List</option>
                            <option v-for="dealer in gold">
                                {{ dealer.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<style scoped>
.form_section {
    min-height: 0;
}
.step_form_main {
    padding: 25px 32px 65px;
}
.step_form_main.step_two {
    padding-top: 30px;
}
.button_cont .custom_btn {
    width: 10rem;
}

.button_cont button {
    font-weight: 400;
    width: 100%;
    border-width: 0;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #f6b221;
    color: #003865;
    height: 57px;
    font-size: 21px;
    font-family: inherit;
}

.button_cont button.prev_btn {
    float: left;
    font-weight: 300;
    background-color: #003865;
    color: #f6b221;
}

.dealer_etp_status button {
    width: 150px;
}
</style>

<script>
import * as VueGoogleMaps from "vue2-google-maps";

import Slick from "vue-slick";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GMAP_API_KEY,
        libraries: "places",
        components: "country:us"
    },
    installComponents: true
});

import GoogleMap from "./GoogleMap";
import QuoteStepForm from "./QuoteStepFormRedesign.vue";

export default {
    name: "finddealer",
    data() {
        return {
            dealers: {},
            show_results: "none",
            show_distance: 0,
            search_status: 0,
            params: {
                zipcode: "",
                distance: 10,
                latitude: 0.0,
                longitude: 0.0,
                type: 1,
                showroom: false,
                primary_segment: [],
	            international: false,
            },
            distances: this.distanceOptions(),
            has_errors: false,
            domain: process.env.MIX_APP_DOMAIN,
            slickOptions: {
                slidesToShow: 3,
                slidesToScroll: 3,
                vertical: true,
                infinite: false
            }
        };
    },
    props: {
        service_types: {},
        home_types: {},
        new_buy_plans: {},
        window_amount: {},
        window_importance: {},
        door_amount: {},
        interested_products: {},
        lead_types: {},
        business_types: {},
        project_conditions: {},
        project_types: {},
        coi_dealers: {},
        states: {},
        homes_built_per_year: "",
    },
    components: {
        GoogleMap,
        QuoteStepForm,
        Slick
    },
    created() {},
    mounted() {
        this.initApiToken();
        this.geoLocationRequest();
    },
    methods: {
        submitSearch() {
            if (this.search_status === 0) {
                if (this.params.international) {
                    this.searchDealers(1);
                } else {
                    this.fetchLatLong();
                }
            } else if (this.search_status === 1) {
                this.searchDealers(1);
            }
        },
        fetchLatLong() {
            if (!this.params.zipcode) {
                this.has_errors = true;
                return true;
            }

            this.show_results = "loading";

            window.axios.defaults.headers.common = {
                Accept: "application/json",
                "Content-Type": "application/json"
            };

            var map_url =
                "https://maps.googleapis.com/maps/api/geocode/json?key=" +
                process.env.MIX_GMAP_API_KEY +
                "&components=postal_code:" +
                this.params.zipcode;
            axios.get(map_url).then(
                response => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0) {
                            var location =
                                response.data.results[0].geometry.location;
                            this.params.latitude = location.lat;
                            this.params.longitude = location.lng;
                            this.searchDealers(1);
                        } else {
                            this.show_results = "none";
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        searchDealers(search_type) {
            this.show_results = "loading";
            this.params.type = search_type;

            axios.post("/api/dealer/search", this.params).then(
                response => {
                    if (response.data.success && response.data.dealers) {
                        this.dealers = response.data.dealers;
                        this.show_results = "yes";
                        this.show_distance = search_type == 1 ? 1 : 0;
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        distanceOptions() {
            var distance = [
                { id: 10, value: "10 Miles" },
                { id: 15, value: "15 Miles" },
                { id: 20, value: "20 Miles" },
                { id: 25, value: "25 Miles" },
                { id: 30, value: "30 Miles" }
            ];

            return distance;
        },
        showQuotePopup(dealer) {
            this.$modal.show(
                QuoteStepForm,
                {
                    dealer: dealer,
                    service_types: this.service_types,
	                brand: dealer.brand_id ? dealer.brand_id : null,
                    home_types: this.home_types,
                    new_buy_plans: this.new_buy_plans,
                    window_amount: this.window_amount,
                    door_amount: this.door_amount,
                    window_importance: this.window_importance,
                    interested_products: this.interested_products,
                    lead_types: this.lead_types,
                    business_types: this.business_types,
                    project_conditions: this.project_conditions,
                    project_types: this.project_types,
                    states: this.states,
                    homes_built_per_year: this.homes_built_per_year,
                },
                {
                    height: "auto"
                },
                {
                    scrollable: true
                },
                {
                    resizable: true
                }
            );
        },
        showBusinessPopup() {
            this.$modal.show(BusinessContact);
        },
        initApiToken() {
            axios
                .post("/api/token/request", {
                    dealer_id: "general",
                    secret: "general"
                })
                .then(
                    response => {
                        if (response.data.success) {
                            console.log(response.data.message);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
        },
        geoLocationRequest() {
            var $this = this;
            var geoSuccess = function(position) {
                $this.params.latitude = position.coords.latitude;
                $this.params.longitude = position.coords.longitude;
                $this.searchDealers(0);
            };

            var geoError = function(error) {
                console.log(
                    "Error occurred. Error code: " +
                        error.code +
                        ", Error message: " +
                        error.message
                );
            };

            navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        },
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        }
    },
    computed: {
        platinum() {
            return _.filter(this.coi_dealers, { level: 3 });
        },
        diamond() {
            return _.filter(this.coi_dealers, { level: 2 });
        },
        gold() {
            return _.filter(this.coi_dealers, { level: 1 });
        }
    }
};
</script>
